<template>
  <van-popup v-model:show="isShow" position="bottom" :style="{
    height: '80vh',
    borderRadius: '16px 16px 0 0',
    padding: '0 16px',
    overflow: 'hidden'
  }" @closed="handleClose">
    <div class="close">
      <img @click="handleClose" src="@/assets/icons/ic_Close@2x.png" alt="" />
    </div>
    <div class="content-box">
      <div class="content">
        <div class="title" v-if="promotes.length">{{ $t('優惠福利') }}</div>
        <div class="box" v-for="(item,index) in promotes" :key="index" @click="handleClick(item)">
          <div class="left">
            <div class="tag" v-if="item.tag">{{ item.tag }}</div>
            <div class="kongtag" v-else></div>
          </div>
          <div class="right">
            <div class="item">
              <div class="text">{{ item.name }}</div>
              <van-icon name="arrow" color="#BDBDBD" class="icon-arrow" />
            </div>
          </div>
        </div>
        <div class="title" style="padding-top:16px ;" v-if="canGetDisCoupons.length">{{ $t("領取優惠券") }}</div>
        <div class="tigs" v-if="canGetDisCoupons.length">{{ $t("優惠券可在個人中心索取，並会在下單時自動使用") }}</div>
        <div v-if="canGetDisCoupons.length">
          <limitCouponLayout :list="canGetDisCoupons" @success="handleSuccess" />
        </div>
      </div>
    </div>
    <div class="btn" @click="handleGoGet()">
      {{ $t("領券中心") }}
    </div>
  </van-popup>
</template>
<script>
import Mixins from "@/utils/mixins"
import { getProductSpecification } from "@/api/productdetails.js"
import limitCouponLayout from "@/components/limit-coupon-layout/index.vue"
export default {
  name: "discountDialog",
  props: {
    list: {
      type: Array,
      default: []
    },
    promotes: {
      type: Array,
      default: []
    },

  },
  watch: {
    list: {
      handler (val) {
        this.canGetDisCoupons = val
      },
      deep: true
    }
  },
  components: {
    limitCouponLayout
  },
  data () {
    return {
      isShow: false,
      canGetDisCoupons: []
    }
  },
  mixins: [Mixins],
  async created () { },

  async mounted () {
  },
  methods: {
    handleSuccess (data) {
      this.$emit('success', data)
    },
    handleGoGet () {
      this.handleClose()
      this.$router.push("/coupon")
    },
    handleClick (data) {
      this.handleClose()
      this.$router.push(`${data.link}`)
    },
    show () {
      this.isShow = true
    },
    handleClose () {
      this.isShow = false
    },
  }
}
</script>
<style scoped lang="scss">
.close {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 0;

  img {
    width: 24px;
    height: 24px;
  }
}

.btn {
  position: absolute;
  bottom: 6px;
  left: 16px;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #90D12E;
  font-weight: 500;
  font-size: 15px;
  color: #90D12E;
  line-height: 44px;
  text-align: center;
  height: 44px;
  width: 92%;
}

.content-box {
  height: 100%;
  padding: 40px 0px 60px;
  box-sizing: border-box;
}

.content {

  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 24px;
    text-align: left;
    margin-bottom: 4px;
  }

  .tigs {
    margin-top: 4px;
    font-weight: 400;
    font-size: 13px;
    color: #666666;
    line-height: 20px;
    text-align: left;
    margin-bottom: 16px;
  }

  .box {
    display: flex;
    align-items: flex-start;

    .left {
      .tag {
        min-width: 30px;
        padding: 0 3px;
        box-sizing: border-box;
        height: 18px;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #F6B6AE;
        font-weight: 400;
        font-size: 11px;
        color: #E84935;
        line-height: 18px;
        text-align: center;
      }

      .kongtag {
        min-width: 30px;
        height: 18px;
      }
    }

    .right {
      margin-left: 6px;

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .text {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 13px;
          color: #1A1A1A;
          line-height: 20px;
        }

        .icon-arrow {
          margin-left: 6px;
        }
      }
    }
  }
}
</style>
