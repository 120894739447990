<template>
  <div class="shopCart">
    <div class="header">
      <div class="header-left">{{ $t('購物車') }}<span v-if="standardLength">({{ standardLength }})</span></div>
      <div class="header-right" v-if="cartList.length" @click="handleOnManage">{{ !isAllDelete ? $t('管理') : $t('完成') }}
      </div>
    </div>
    <div class="main" v-if="cartList.length">
      <goodItems v-for="(item,index) in cartList" :key="index" :objData="item" @Remove="handeRemoveAmount"
        @Add="handeAddAmount" @getIsAmount="handlegetIsAmount" @onSelect="handleOnselect" @onDelete="handleonDelete" />
    </div>
    <div v-else class="empty">
      <img src="@/assets/image/emptyCart.png">
      <div class="title">{{ $t('購物車暫無商品') }}</div>
      <div class="txt">{{ $t('快去挑選自己喜歡的商品吧') }}~</div>
      <div class="empty-btn" @click="handlegoHome">{{ $t('去逛逛') }}</div>
    </div>
    <div class="footer">
      <div class="footer-left">
        <div class="radio">
          <img src="@/assets/icons/did_select.png" v-if="!Allchecked" @click="handleOnAllchecked(true)">
          <img v-else src="@/assets/icons/green_select.png" @click="handleOnAllchecked(false)">
        </div>
        <div class="allSelect">{{ $t('全选') }}</div>
      </div>
      <div class="footer-right" v-if="!isAllDelete">
        <divc class="fee">
          <div class="total">{{ $t('總計') }}：<span>{{ formatNumber(Totalprice) }}</span></div>
          <div class="freight">{{ $t('不含運費') }}</div>
        </divc>
        <div class="settlement" @click="handleSettlement">{{ $t('去結算') }}</div>
      </div>
      <div v-else class="delete-btn" @click="handlebatchDel"> {{ $t('删除') }}</div>
    </div>
    <promptDialog ref="promptDialogRef" :confirmText="confirmText" :content="content" @success="handleSuccess" />
    <tabbar></tabbar>
  </div>
</template>
<script>
import tabbar from "@/components/tabbar/index"
import Mixins from "@/utils/mixins"
import { getShoppingCartList2, deleteShoppingCart2, changeShoppingCart } from "@/api/shopCart"
import { getProductcreateTempOrder } from "@/api/productdetails"
import goodItems from "./components/good-items.vue"
import promptDialog from "@/components/promptDialog/index.vue"
import { mapState } from "vuex"
export default {
  name: "shopCart",
  mixins: [Mixins],
  components: {
    promptDialog,
    goodItems,
    tabbar
  },
  data () {
    return {
      confirmText: this.$t('刪除'),
      content: this.$t('確認刪除？'),
      objData: {},
      goodsList: [],
      standardLength: null,
      Totalprice: 0,
      isAllDelete: false,
      delList: [],
    }
  },
  mounted () {
    this.getData()
  },
  computed: {
    ...mapState("shopCart", ["cartList"]),
    Allchecked () {
      if (this.cartList.length) {
        const blom = this.cartList.every(item => item.checked)
        return blom
      } else {
        return false
      }

    },
  },
  methods: {
    handlegoHome () {
      this.$router.replace("/home")
    },
    async getData () {
      const res = await getShoppingCartList2({ showSelected: false })
      if (res.resultID === 1200) {
        this.objData = res.data
        this.goodsList = res.data.list.map(item => item.goods).flat(1).map(it => {
          return {
            ...it,
            checked: this.cartList.find(items => items.searchProductId === it.searchProductId)?.checked
          }
        })
        this.$store.dispatch("shopCart/setgoodsList", this.goodsList)
        await this.getLength()
      }
    },
    handeRemoveAmount (searchProductId) {
      this.cartList.forEach(item => {
        if (item.searchProductId === searchProductId) {
          item.amount -= 1
          this.getIsAmount(item, "1")
          this.getLength()
          changeShoppingCart({ count: item.amount, searchProductId: item.searchProductId })
        }
      })
    },
    handeAddAmount (searchProductId) {
      this.cartList.forEach(item => {
        if (item.searchProductId === searchProductId) {
          item.amount += 1
          this.getIsAmount(item)
          this.getLength()
          changeShoppingCart({ count: item.amount, searchProductId: item.searchProductId })
        }
      })
    },
    handlegetIsAmount (data) {
      this.cartList.forEach(item => {
        if (item.searchProductId === data.searchProductId) {
          this.getIsAmount(item)
          this.getLength()
          changeShoppingCart({ count: item.amount, searchProductId: item.searchProductId })
        }
      })

    },
    // 数量判断
    getIsAmount (item, type) {
      if (item.amount % item.sellPartCount != 0) {
        if (type == "1") {
          item.amount -= item.sellPartCount / 2
          item.amount =
            item.sellPartCount * Math.floor(item.amount / item.sellPartCount)
        } else {
          item.amount += item.sellPartCount
          item.amount =
            item.sellPartCount * Math.floor(item.amount / item.sellPartCount)
        }
      }
      if (item.amount > item.stock && item.isBuyLimited) {
        item.amount = item.stock
        return this.$toast(`${this.$t('限購')}${item.stock}${item.unit}`)
      } else if (item.amount > item.stock) {
        item.amount = item.stock
        return this.$toast(this.$t('庫存不足'))
      }
    },
    // 获取已选长度
    getLength () {
      // 购物车所有商品的数量
      this.standardLength = this.cartList.reduce(
        (prve, next) => Number(prve) + Number(next.amount),
        0
      )
      this.$store.dispatch("shopCart/setCount", this.standardLength)
      // 选中的商品合计
      this.Totalprice = this.cartList.filter(item => item.checked).reduce((prve, next) => {
        const price = Number(next.amount * next.unitPrice)
        return Number(prve) + price
      },
        0
      )

    },
    handleOnselect (searchProductId) {
      this.cartList.forEach(item => {
        if (item.searchProductId === searchProductId) {
          item.checked = !item.checked
          this.getLength()
        }
      })

      this.$store.dispatch("shopCart/setgoodsList", this.cartList)
    },
    async handleSuccess () {
      const res = await deleteShoppingCart2({
        goods: this.delList.map(item => {
          return {
            id: item.searchProductId
          }
        }),
        isCollect: true
      })
      if (res.resultID === 1200) {
        this.$toast("删除成功")
      }
      this.getData()
    },
    async handleonDelete (searchProductId) {
      const data = this.cartList.filter(item => item.searchProductId === searchProductId)
      if (data?.length) {
        this.delList = []
        await this.handleDelete(data)
      }
    },
    // 删除
    handleDelete (data) {
      this.delList = data
      this.$refs.promptDialogRef.open()
    },
    // 批量删除
    async handlebatchDel () {
      const data = this.cartList.filter(item => item.checked)
      this.delList = []
      if (!data.length) {
        this.$toast(this.$t('至少選擇壹個商品'))
        return
      }
      await this.handleDelete(data)
    },
    // 全选
    async handleOnAllchecked (blom) {
      if (this.cartList.length) {
        this.cartList.forEach(item => {
          item.checked = blom
          this.getLength()
        })
        this.$store.dispatch("shopCart/setgoodsList", this.cartList)
      }
    },
    // 管理
    handleOnManage () {
      this.isAllDelete = !this.isAllDelete
    },
    async handleSettlement () {
      if (!this.cartList.some(item => item.checked)) {
        this.$toast(this.$t('至少選擇壹個商品'))
        return
      }
      let goods = this.cartList.filter(it => it.checked).map(item => {
        return {
          ...item,
          id: item.searchProductId,
          count: item.amount
        }
      })
      const res = await getProductcreateTempOrder({ goods, isFromShoppingCart: true })
      if (res.resultID !== 1200) {
        this.$toast(res.message)
        return
      }
      this.$router.push("/order/confirm2")
      // var _this = this
      // var func1 = function () {
      //   mmm.placeOrderList(JSON.stringify(goods))
      // }
      // var func2 = function () {
      //   window.webkit.messageHandlers.placeOrderList.postMessage({ goods: JSON.stringify(goods) })
      // }
      // var func3 = async function () {

      // }

      // this.excFunc(func1, func2, func3)
    }
  }
}
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
}

.radio {
  width: 24px;
  height: 24px;
  font-size: 0;
}

.shopCart {
  background: #FFFFFF;
}

.header {
  padding: 32px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  position: sticky;
  z-index: 1;
  left: 0;
  top: 0;

  .header-left {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 17px;
    color: #1A1A1A;
    line-height: 24px;
    text-align: left;

    span {
      font-size: 14px;
    }
  }

  .header-right {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #1A1A1A;
    line-height: 24px;
    text-align: right;
  }
}

.main {
  padding-bottom: 200px;
}

.empty {
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-items: center;
  font-size: 0;

  img {
    width: 120px;
    height: 120px;
    margin-bottom: 4px;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
    line-height: 24px;
    text-align: center;
    margin-bottom: 4px;
  }

  .txt {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 22px;
    text-align: center;
    margin-bottom: 24px;
  }

  .empty-btn {
    width: 175px;
    height: 36px;
    background: #90D12E;
    border-radius: 8px 8px 8px 8px;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    line-height: 36px;
    text-align: center;
  }
}

.footer {
  position: fixed;
  background: #FFFFFF;
  width: 100%;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, 0%);

  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .footer-left {
    display: flex;
    align-items: center;

    .allSelect {
      margin-left: 4px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 20px;
    }
  }

  .footer-right {
    display: flex;
    align-items: center;

    .fee {
      margin-right: 8px;

      .total {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 20px;

        span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 800;
          font-size: 16px;
          color: #F82207;
          line-height: 24px;
        }
      }

      .freight {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 11px;
        color: #666666;
        line-height: 16px;
        text-align: right;
      }
    }

    .settlement {
      width: 101px;
      height: 44px;
      background: #90D12E;
      border-radius: 8px 8px 8px 8px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
      line-height: 44px;
      text-align: center;
    }
  }

  .delete-btn {
    width: 101px;
    height: 44px;
    border-radius: 8px 8px 8px 8px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 15px;
    color: #F82207;
    line-height: 44px;
    text-align: center;
    border: 1px solid #F82207;
  }
}
</style>