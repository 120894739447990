import baseURL from "@/utils/config"
import request from "@/plugins/axios"
const langEnv = window.localStorage.getItem("langEnv")
// 首页数据
export function gethotWord (data) {
  return request({
    url: `${baseURL.api}/api/indexNew-hotWord`,
    method: "post",
    data,
  })
}
// 商品列表
export function getProductList (data) {
  return request({
    url: `${baseURL.api}/api/product/list4phone`,
    method: "post",
    data,
  })
}
// 搜索 自动填充
export function getSuggestList (data) {
  return request({
    url: `${baseURL.api}/api/suggest`,
    method: "post",
    data,
  })
}
// 全部分类
export function getParentCategoryList (data) {
  return request({
    url: `${baseURL.api}/api/GetParentCategoryList`,
    method: "post",
    data,
  })
}
// 全部分类 -id 查询
export function getCategoryListById (data) {
  return request({
    url: `${baseURL.api}/api/GetCategoryListById`,
    method: "post",
    data,
  })
}
// 品牌
export function getSearchBrand (data) {
  return request({
    url: `${baseURL.api}/api/searchBrand`,
    method: "post",
    data,
  })
}

