<template>
  <div class="verify-box">
    <nav-bar :title="$t(isSetPwd ? '設置密碼' : verifyWay == 1 ? '電子郵件注冊' : '電話號碼注冊')" :isFixed="true" :goBack="goBack"
      :bgc="'background: rgba(224, 239, 201)'"></nav-bar>
    <div class="bg"></div>
    <div class="verify-form">
      <div v-if="!isSetPwd">
        <van-form v-if="verifyWay == 1" @submit="onSubmit(1)">
          <van-field v-model="params.email" :placeholder="$t('請輸入郵箱賬號')" />
          <div class="error" v-if="verifyobj.email">
            {{ $t("請輸入") }}{{ $t("有效電子郵箱地址") }}
          </div>
          <van-field v-model="CaptchaNewCode" :placeholder="$t('請輸入郵箱驗證碼')" />
          <div class="error" v-if="isCode">
            {{ $t("請輸入驗證碼") }}
          </div>
          <div class="btn-getcode" v-if="countDown !== 0">
            {{ countDown }}s，{{ $t("再次獲取驗證碼") }}
          </div>
          <div class="btn-getcode" v-else @click="getEmailCode('2')">
            {{ $t("獲取驗證碼") }}
          </div>
          <div style="margin: 12px 0;">
            <van-button color="#90D12E" block type="primary" native-type="submit">
              {{ $t('下一步') }}
            </van-button>
          </div>
        </van-form>
        <van-form v-else @submit="onSubmit(2)">
          <div class="AddressList m12" @click="show = true">
            <div :style="phoneLabel ? 'color:#1A1A1A;' : ''">
              {{ phoneLabel ? phoneLabel : $t("區號") }}
            </div>
            <img src="@/assets/icons/arrow-rightactive.png" alt="" />
          </div>
          <van-field v-model="params.phone" type="text" :placeholder="$t('電話號碼')" />
          <div class="error" v-if="verifyobj.phone">
            {{ $t("請輸入") }}{{ $t("有效電話號碼") }}
          </div>
          <van-field v-model="CaptchaNewCode" :placeholder="$t('請輸入電話驗證碼')" />
          <div class="error" v-if="isCode">
            {{ $t("請輸入驗證碼") }}
          </div>
          <div class="btn-getcode" v-if="countDown !== 0">
            {{ countDown }}s，{{ $t("再次獲取驗證碼") }}
          </div>
          <div class="btn-getcode" v-else @click="getEmailCode('2')">
            {{ $t("獲取驗證碼") }}
          </div>
          <div style="margin: 12px 0;">
            <van-button color="#90D12E" block type="primary" native-type="submit">
              {{ $t('下一步') }}
            </van-button>
          </div>
        </van-form>

        <div class="agree">
          <van-checkbox checked-color="#90D12E" icon-size="16px" shape="square" v-model="checked">{{ $t("我已閲讀并同意") }}
            <span class="agree-text" @click="$router.push(`/about?id=3&langEnv=${langEnv}`)">{{ $t("《Rith 隱私政策》")
              }}</span>
            {{ $t("和")
            }} <span class="agree-text" @click="$router.push(`/about?id=4&langEnv=${langEnv}`)"> {{ $t("《Rith 服務協議》")
              }}</span></van-checkbox>
        </div>
      </div>
      <div v-else>
        <van-form @submit="goLogin">
          <van-field class="passwordhide" v-model="params.password" type="password"
            :placeholder="$t('最少6個字符，包含字母和數字')" />
          <div class="error" v-if="verifyobj.password">
            {{ $t("請輸入6-15位含字母和數字密碼") }}
          </div>
          <van-field class="passwordhide" v-model="params.repassword" type="password" :placeholder="$t('請再次確認密碼')" />
          <div class="error" v-if="verifyobj.passwordregx">
            {{ $t("兩次密碼輸入不一致") }}
          </div>
          <div style="margin: 28px 0 0;">
            <van-button color="#90D12E" plain block style="margin-bottom: 12px;" @click="goAuthentication">
              {{ $t('認證門診') }}
            </van-button>
            <van-button color="#90D12E" block type="primary" native-type="submit">
              {{ $t('直接登錄') }}
            </van-button>
          </div>
        </van-form>
      </div>

    </div>
    <div v-if="!isSetPwd" class="cut" @click="handleOnCut()">{{ verifyWay == 1 ? $t('切換手機註冊') : $t('切換郵箱註冊') }}</div>
    <van-dialog v-model:show="isRegister" title="提示" :showConfirmButton="false">
      <div class="register-dialog">
        <div class="register-text">
          <div>{{ $t("注冊Rith賬號") }}，{{ $t("需閲讀並同意") }}</div>
          <div>
            <span @click="$router.push(`/about?id=3&langEnv=${langEnv}`)">{{ $t("《Rith 隱私政策》") }}</span>
            {{ $t("和")
            }} <span @click="$router.push(`/about?id=4&langEnv=${langEnv}`)">{{ $t("《Rith 服務協議》") }}</span>。
          </div>
        </div>
        <div class="register-btn">
          <div class="Cancel" @click="isRegister = false">
            {{ $t("取消") }}
          </div>
          <div class="Confirm" @click="gotoRegister">
            {{ $t("我已閲讀并同意") }}
          </div>
        </div>
      </div>
    </van-dialog>
    <van-popup v-model:show="show" position="bottom" :close-on-click-overlay="false"
      :style="{ height: '40%',borderRadius: '16px 16px 0 0' }">
      <div class="titleArea">
        <div></div>
        <div>{{ $t('國家地區') }}</div>
        <div class="close">
          <img @click="show = false" style="width: 100%" src="@/assets/icons/ic_Close@2x.png" alt="" />
        </div>
      </div>
      <div class="phone-list">
        <div :class="['phone-item',{ actvie: item.code == params.areaCode }]" v-for="item in phoneCode" :key="item.code"
          @click="handleAreaCode2(item)">
          <div class="phone-text">{{ item.name }}</div>
          <img class="phone-check" v-if="item.code == params.areaCode" src="@/assets/icons/check.png" alt="" />
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import JSEncrypt from "jsencrypt"
import { getCaptchaNew, getvalidateEmail, registerNewLogin, validatePhone, validatePhoneCaptcha } from "@/api/user"
import { mapState } from "vuex"
import mixins from "@/utils/mixins.js"
import { setToken } from "@/utils/token.js"
export default {
  beforeRouteEnter (to, form, next) {
    if (form.path.includes("about")) {
      to.query.isCache = true
    }
    next()
  },
  name: "verify",
  mixins: [mixins],
  data () {
    return {
      // 注册方式
      verifyWay: 2,
      // 是否设置密码
      isSetPwd: false,
      checked: false,
      params: {
        userName: "",
        password: "",
        repassword: "",
        email: "",
        platform: 4,
        name: "",
        address: "",
        captcha: "",
        phone: "",
        province: "",
        city: "",
        district: "",
        street: "",
        businessLicense: "",
        areaCode: "",
        invitationCode: ""
      },
      verifyobj: {
        passwordregx: "",
        password: "",
        email: "",
        phone: "",
      },
      isCode: false,
      phoneLabel: '',
      CaptchaNewCode: "",
      show: false,
      isRegister: false,
      countDown: 0,
      verifyList: []
    }
  },
  watch: {
    params: {
      deep: true,
      async handler (val) {
        try {
          this.$store.dispatch("user/getregisterForm", this.params)
          this.$store.dispatch("user/getFileList", this.fileList)
        } catch (error) { }
      }
    }
  },
  computed: {
    ...mapState("user", ["registerForm", "phoneCode"])
  },
  async created () {
    await this.$store.dispatch("user/getPhoneCode")
    this.params.areaCode = this.phoneCode[0]?.code
    this.phoneLabel = this.phoneCode[0]?.name
    this.langEnv = window.localStorage.getItem("langEnv")
    if (this.$route.query.isCache) {
      this.params = { ...this.registerForm }
      this.fileList = this.cacheFileList
      this.selectTitle = `${this.params.province.split("●")[1] || ''}${this.params?.city?.split("●")[1]
        ? "/" + this.params?.city?.split("●")[1]
        : ""
        }${this.params?.district?.split("●")[1]
          ? "/" + this.params?.district?.split("●")[1]
          : ""
        }`
    }
  },
  methods: {
    // 获取验证码
    async getEmailCode (type) {
      const isSubmit = await this.handleExamine()
      if (!isSubmit) return
      if (!this.checked) {
        this.$toast("需閲讀並同意")
        return
      }
      const publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC/TS09a8Uez9/XfkDdK+Exx3ltVxrUlCjNPqRheMB/63iHyjx5k+N/48Nh9NFdCQKLEV/WxHQoszEBEh/sFofQuAx1wB9bcOtD98DT8Wkd53XmojxVfhtsVjeI/IBMim56mrO/LcKQDwc/6BsbFvilde002wx3CQ91OCw8XMptTwIDAQAB`
      let obj = this.verifyWay === 1 ? {
        email: this.params.email,
        type: "register",
        captcha: ""
      } : {
        phone: this.params.phone,
        type: "register",
        areaCode: this.params.areaCode,
        captcha: ""
      }
      let jsEncrypt = new JSEncrypt()
      jsEncrypt.setPublicKey(publicKey)
      let dataToEncrypt = JSON.stringify(obj)
      const res = await getCaptchaNew({
        id: jsEncrypt.encrypt(dataToEncrypt)
      })
      if (res.resultID !== 1200) return
      if (type == "2") {
        this.countDown = 60
        this.getTime()
      }
    },
    getTime () {
      const timer = setInterval(() => {
        this.countDown--
        if (this.countDown === 0) {
          clearInterval(timer) // 清除定时器
          this.countDown = 0
        }
      }, 1000)
    },
    // 切换注册方式
    handleOnCut () {
      this.verifyWay = this.verifyWay === 1 ? 2 : 1
    },
    goBack () {
      if (this.isSetPwd) {
        this.isSetPwd = false
      } else {
        this.$router.back()
      }
    },
    // 选择国家地区
    handleAreaCode2 (item) {
      this.show = false
      this.params.areaCode = item.code
      this.phoneLabel = item.name
    },
    gotoRegister () {
      this.isRegister = false
      this.checked = true
    },
    // 验证
    handleExamine () {
      const regxEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      let regxPhone = /^1[3456789]\d{9}$/
      // 密码
      const regex = /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/

      switch (this.params.areaCode) {
        case '+852': // 香港
          regxPhone = /^(?:\+852)?[0-9]{8}$/
          break
        case '+86': // 中国大陆
          regxPhone = /^(?:\+86)?1[3-9][0-9]{9}$/ // 11位手机号，以1开头
          break
        case '+1': // 美国/加拿大
          regxPhone = /^(?:\+1)?[0-9]{10}$/ // 10位手机号
          break
        case '+853': // 澳门
          regxPhone = /^(?:\+853)?[0-9]{8}$/ // 8位手机号
          break
        // 可以添加其他国家的区号和对应的正则表达式
        default:
          regxPhone = /^(?:\+852)?[0-9]{8}$/
      }
      this.verifyList = []
      if (!regxEmail.test(this.params.email) && this.verifyWay === 1) {
        this.verifyobj["emailregx"] = true
      } else {
        delete this.verifyobj["emailregx"]
      }
      if (!regxPhone.test(this.params.phone) && this.verifyWay === 2) {
        this.verifyobj["phone"] = true
      } else {
        delete this.verifyobj["phone"]
      }

      if (this.isSetPwd) {
        if (!regex.test(this.params.password)) {
          this.verifyobj["password"] = true
          return false
        } else {
          delete this.verifyobj["password"]
        }
        if (this.params.repassword != this.params.password) {
          this.verifyobj["passwordregx"] = true
          return false
        } else {
          delete this.verifyobj["passwordregx"]
        }
      } else {
        delete this.verifyobj["password"]
        delete this.verifyobj["passwordregx"]
      }

      const keys = Object.keys(this.verifyobj)

      keys.forEach(item => {
        this.validatoFormFormcheck(item)
      })
      for (const key in this.verifyobj) {
        this.verifyobj[key] = ""
      }
      this.verifyList.forEach(item => {
        this.verifyobj[item] = true
      })
      if (this.verifyList.length > 0) {
        return false
      } else {
        return true
      }
    },
    validatoFormFormcheck (label) {
      if (!this.params[label]) {
        console.log('label', label)

        this.verifyList.push(label)
      } else {
        const index = this.verifyList.findIndex(item => item === label)
        if (index != -1) {
          this.verifyList.splice(index, 1)
        }
      }
      if (this.verifyWay === 1) {
        this.verifyList = this.verifyList.filter(item => item !== 'phone')
      } else {
        this.verifyList = this.verifyList.filter(item => item !== 'email' && item !== 'emailregx')
      }
    },
    // 下一步
    async onSubmit () {
      const isSubmit = await this.handleExamine()
      if (!isSubmit) return
      // 验证手机或邮箱
      let res = {}
      if (this.verifyWay === 1) {
        res = await getvalidateEmail({ email: this.params.email })
      } else {
        res = await validatePhone({ phone: this.params.phone, areaCode: this.params.areaCode })
      }
      if (res.resultID !== 1200) return
      // 验证验证码
      const CaptchaNewCode = this.CaptchaNewCode.trim()
      if (!CaptchaNewCode) {
        this.isCode = true
        return
      }
      let result = {}
      if (this.verifyWay === 1) {
        result = await validatePhoneCaptcha({ email: this.params.email, type: 'register', captcha: this.CaptchaNewCode, isregiested: false })
      } else {
        result = await validatePhoneCaptcha({ phone: this.params.phone, areaCode: this.params.areaCode, type: 'register', captcha: this.CaptchaNewCode, isregiested: false })
      }
      if (result.resultID !== 1200) return
      if (!this.checked) {
        this.isRegister = true
        return
      }

      this.isSetPwd = true
    },
    // 去认证
    async goAuthentication () {
      const isSubmit = await this.handleExamine()
      if (!isSubmit) return
      this.params.captcha = this.CaptchaNewCode
      this.$router.push('/verify')
    },
    // 直接登录
    async goLogin () {
      const isSubmit = await this.handleExamine()
      if (!isSubmit) return
      this.params.captcha = this.CaptchaNewCode
      if (this.$route.query.invitationCode) {
        this.params.invitationCode = this.$route.query.invitationCode
      }

      let formData = new FormData()
      Object.entries({ ...this.params }).forEach(([key, value]) => {
        formData.append(key, value)
      })
      this.loading = true
      const result = await registerNewLogin(formData)
      if (result.resultID === 1200) {
        // this.$toast(result.message)
        setToken(result.data.token)
        localStorage.setItem("userName", result.data.userName)
        localStorage.setItem("roles", result.data.roles)
        this.$router.push("/home")
      }
      this.loading = false
    },
  }
}
</script>
<style lang="scss" scoped>
.verify-box {
  .bg {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 148px;
    background: linear-gradient(180deg, rgba(144, 209, 46, 0.3) 0%, rgba(144, 209, 46, 0) 100%);
    border-radius: 0px 0px 0px 0px;

  }

  .cut {
    font-weight: 500;
    font-size: 15px;
    color: #3491FA;
    line-height: 24px;
    text-align: center;
    // position: fixed;
    margin: 100px 0 0;
    // left: 50%;
    // bottom: 20%;
    // transform: translate(-50%, -80%);
  }

  .verify-form {
    padding: 68px 26px 0;
  }

  .btn-getcode {
    text-align: right;
    font-weight: 500;
    font-size: 15px;
    color: #3491FA;
    line-height: 24px;
    margin-bottom: 28px;
  }

  .error {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #f82207;
    line-height: 18px;
    padding-left: 12px;
    margin-top: 1px;
  }

  :deep(.van-checkbox) {
    align-items: start;

    .van-checkbox__icon {
      margin-top: 3px;

      .van-badge__wrapper {
        border-radius: 2px;
      }
    }
  }

  .agree {
    font-size: 14px;

    .agree-text {
      color: #90d12e;
    }
  }

  .AddressList {
    position: relative;
    z-index: 2;
    width: 100%;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #ebebeb;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999999;
    font-size: 16px;
    background-color: #fff;
    margin-bottom: 12px;

    img {
      width: 20px;
    }
  }
}

.van-field {
  background-color: #fff;
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #ebebeb;
  margin-bottom: 12px;
  padding: 16px;

  .van-field__control {
    font-size: 16px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;

    &::placeholder {
      color: #999999;
    }
  }
}

.titleArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 0;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;

  .close {
    width: 20px;
  }
}

.phone-list {
  padding: 20px 16px;

  .actvie {
    border: 2px solid #90d12e !important;
  }

  .phone-item {
    padding: 20px;
    position: relative;
    background: #f8f8f8;
    border-radius: 8px 8px 8px 8px;
    margin-bottom: 12px;
    border: 2px solid transparent;

    .phone-text {
      font-size: 16px;
      color: #1a1a1a;
      line-height: 16px;
      text-align: center;
    }

    .phone-check {
      position: absolute;
      width: 24px;
      bottom: -1px;
      right: -1px;
    }
  }
}

.register-dialog {
  padding: 24px;
}

.register-text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 15px;
  color: #666666;
  line-height: 24px;
  text-align: center;

  span {
    color: #3491fa;
  }
}

.register-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Cancel,
  .Confirm {
    flex: 1;
    text-align: center;
    margin-top: 32px;
  }

  .Cancel {
    background: #eeeeee;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
    margin-right: 12px;
  }

  .Confirm {
    font-size: 16px;
    color: #ffffff;
    background-color: #90d12e;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
  }
}
</style>