<template>
  <div class="page">
    <div class="sticky">
      <search :isSwipe="3" @change="handleSearch" />
      <searchTabs @change="handeleChange" @open="handleopenFilter" />
    </div>
    <div v-if="productsList?.length">
      <div class="product-main">
        <div class="product-main-warper">
          <div class="product-box" v-for="(item,idx) in productsList" :key="idx" @click="handleSkipDetail(item)">
            <div class="box-left">
              <div v-if="item.isDiscount" class="tag">
                <div class="top"><span>{{ item.discount }}</span>%</div>
                <div class="bottom">OFF</div>
              </div>
              <img :src="`${getProductImageUrl(item.picturePath,400)}`" alt="" @error="handleImageError" />
            </div>
            <div class="box-right">
              <div class="box-right-top">
                <div class="brand">{{ item.brandName }}</div>
                <div class="title">{{ item.name }}</div>
              </div>
              <div class="box-right-main" v-if="item.tags?.length">
                <span class="tag" v-for="(it,index) in item.tags">{{ it.title }}</span>
              </div>
              <div class="box-right-bottom">
                <div class="price">
                  <span class="current-price">{{ formatNumber(item.discountPrice) }}</span>
                  <span class="original-price" v-if="item.discountPrice < item.price">{{ formatNumber(item.price)
                    }}</span>
                </div>
                <div class="add" @click.stop="handleBuyProduct(item)">
                  <img src="@/assets/image/search/add.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <van-loading v-if="isMore && !isEmpty" size="13px" class="loading">{{ $t("加载中") }}...</van-loading>
      <div v-if="isEmpty" class="more">{{ $t("沒有更多啦") }}~</div>
    </div>
    <div class="empty" v-else>
      <img src="@/assets/image/search/empty.png">
      <div class="tigs">{{ $t('抱歉沒有找到您想要的商品') }}</div>
    </div>
    <van-popup v-model:show="show" position="right" :style="{ width: '350px',height: '100%' }">
      <div class="content">
        <div class="seat"></div>
        <div class="title" v-if="brandList.length">{{ $t('品牌') }}</div>
        <div class="content-box" v-if="brandList.length">
          <div :class="['box-item',{ 'active': item.isActive }]" v-for="item in brandList" :key="item.id"
            @click="handlebrandSelect(item)">{{
              item.title
            }}</div>
        </div>
        <div class="openAll" v-if="isbrandbtn" @click="handlebrandListOpenAll">{{ brandList.length > 12 ? '收起' : '展开全部'
          }}<van-icon style="margin-left: 4px;" :name="brandList.length > 12 ? 'arrow-up' : 'arrow-down'" /></div>
        <div class="title" v-if="linkCategories.length">{{ $t('相關分類') }}</div>
        <div class="content-box" v-if="linkCategories.length">
          <div :class="['box-item',{ 'active': item.isActive }]" v-for="item in linkCategories" :key="item.id"
            @click="handleSelect(item)">{{
              item.name
            }}</div>
        </div>
        <div class="openAll" v-if="islinkbtn" @click="handlelinkOpenAll">{{ linkCategories.length > 12 ? '收起' : '展开全部'
          }}<van-icon style="margin-left: 4px;" :name="linkCategories.length > 12 ? 'arrow-up' : 'arrow-down'" /></div>
        <div class="fixed-btn">
          <div class="reset" @click="handlereset">{{ $t('重置') }}</div>
          <div class="confirm" @click="handleSure">{{ $t('确定') }}</div>
        </div>
      </div>
    </van-popup>
    <specificationDialog v-if="isSpecificationDialog1" @handleConfirm="handleConfirm" :isAuth="isAuth"
      @handleClose="handlespecificationClose" :show="isSpecificationDialog" :tagtype="submitGoodsList.tagtype"
      :searchProductId="detailId"></specificationDialog>
  </div>
</template>

<script>
import search from "@/components/search/index"
import searchTabs from "@/components/search-tabs/index"
import Config from "@/utils/config.js"
import { getToken, rmoveToken } from "@/utils/token"
import Mixins from "@/utils/mixins"
import MyStorage from "@/utils/storage"
import { getProductList } from "@/api/search"
import { getProduct, getProductcreateTempOrder } from "@/api/productdetails.js"
const history_records = new MyStorage("history_records")
export default {
  mixins: [Mixins],
  components: {
    search,
    searchTabs
  },
  data () {
    return {
      islogin: null,
      imgUrl: Config.imgUrl,
      defaultImg: require('@/assets/image/e-Rith.png'),
      isMore: false,
      isEmpty: false,
      productPage: {
        keyword: "",
        pageIndex: 1,
        pageSize: 10,
        orderBy: 0,
        brandId: null,
        categoryId: null,
        // extProps: null
      },
      productsList: [],
      totalPage: null,
      show: false,
      linkCategories: [],
      linkCategoriesAll: [],
      brandList: [],
      brandListAll: [],
      islinkbtn: false,
      isbrandbtn: false,
      scrollFn: this.throttle(this.handleScroll, 100),
      detailId: null,
      submitGoodsList: {
        goods: [],
        isFromShoppingCart: false,
        isPreOrder: false,
      },
      isSpecificationDialog1: false,
      isSpecificationDialog: false,
      isAuth: {}
    }
  },
  mounted () {
    window.addEventListener("scroll", this.scrollFn, true)
    this.isSpecificationDialog1 = true
    // 在组件或者页面卸载的地方删除监听
    // this.init()
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.scrollFn, true)
    this.isSpecificationDialog1 = false
  },
  methods: {
    // 立即購買
    async handleBuyProduct (row) {
      this.detailId = row.id
      const res = await getProduct({ id: row.id })
      if (res.resultID == 1200) {
        this.list = res.data
        if (this.list.page == null) {
          this.list.page = ""
        }
        this.list.page = this.appendDomainToImgSrc(
          this.list.page,
          Config.imgUrl.replace("/upload", "")
        )
        this.list.page = this.replaceLinks(
          this.list.page,
          Config.imgUrl.replace("/upload", "")
        )
        this.isAuth = { isAuth: this.list.isAuth, text: this.list.text,btnType:2 }

        this.isSpecificationDialog = true
      }

    },
    // 下单
    async handleConfirm (data) {
      var _this = this
      this.submitGoodsList.goods = data.map(item => {
        return {
          searchProductId: item.searchProductId,
          id: item.searchProductId,
          count: item.amount,
          tagType: ''
        }
      })
      var func1 = function () {
        mmm.placeOrderList(JSON.stringify(_this.submitGoodsList.goods))
      }
      var func2 = function () {
        window.webkit.messageHandlers.placeOrderList.postMessage({ goods: JSON.stringify(_this.submitGoodsList.goods) })
      }
      var func3 = async function () {
        const res = await getProductcreateTempOrder(_this.submitGoodsList)
        if (res.resultID === 1200) {
          _this.$router.push("/order/confirm2")
        }
      }

      this.excFunc(func1, func2, func3)

    },
    appendDomainToImgSrc (htmlString, domain) {
      // 正则表达式匹配<img>标签的src属性
      const imgSrcPattern = /<img\s+[^>]*src\s*=\s*["']([^"']+)["'][^>]*>/g

      // 回调函数，用于替换匹配到的src值
      function replaceSrc (match, src) {
        // 检查src是否以http或https开头
        if (src.startsWith('http://') || src.startsWith('https://')) {
          return match // 如果是，则返回原始字符串
        }

        // 拼接域名和原src路径
        const newSrc = domain + src
        // 重新构造<img>标签，确保其他属性不变
        return match.replace(src, newSrc)
      }

      // 使用正则表达式替换所有匹配到的src值
      const resultHtml = htmlString.replace(imgSrcPattern, replaceSrc)

      return resultHtml
    },
    replaceLinks (text, domain) {
      // 正则表达式匹配 href 属性中的路径
      const regex = /href="(\/upload\/files\/file[^"]*)"/g
      // 替换函数
      return text.replace(regex, (match, path) => {
        // 在路径前添加域名
        return match.replace(path, `${domain}${path}`)
      })
    },
    handlespecificationClose () {
      this.isSpecificationDialog = false
    },
    handleSkipDetail (data) {
      this.$router.push(`/product/${data.searchProductId}`)
    },
    // 品牌选择
    handlebrandSelect (data) {
      this.brandList.forEach(item => {
        if (item.link === data.link) {
          item.isActive = true
        } else {
          item.isActive = false
        }
      })
    },
    // 相关分类
    handleSelect (data) {
      this.linkCategories.forEach(item => {
        if (item.id === data.id) {
          item.isActive = true
        } else {
          item.isActive = false
        }
      })
    },
    // 确定
    async handleSure () {
      // this.productPage.extProps = this.brandList.filter(item => item.isActive).map(it => it.link)
      this.productPage.brandId = this.brandList.filter(item => item.isActive)[0]?.link
      this.productPage.categoryId = this.linkCategories.filter(item => item.isActive)[0]?.id
      this.show = false
      await this.init()

    },
    // 重置
    async handlereset () {
      this.brandList.forEach(item => item.isActive = false)
      this.linkCategories.forEach(item => item.isActive = false)
      this.productPage.brandId = ''
      this.productPage.categoryId = ''
      this.show = false
      await this.init()
      // this.productPage.extProps = null
    },
    // 打开筛选抽屉
    handleopenFilter () {
      this.show = true
      if (this.productPage.brandId) {
        this.handlebrandSelect({ link: this.productPage.brandId })
      }
      if (this.productPage.categoryId) {
        this.handleSelect({ id: this.productPage.categoryId })
      }
    },
    // tab 筛选
    handeleChange (data) {
      this.productsList = []
      this.productPage.orderBy = data
      this.init()
    },
    // 搜索回调
    handleSearch (data) {
      this.productsList = []
      if (data?.type == 2) {
        this.productPage.brandId = data?.str
      } else if (data?.type == 3) {
        this.productPage.categoryId = data?.str
      } else {
        this.productPage.keyword = data?.str
      }
      console.log('handleSearch', data)
      this.init()
    },
    async handleScroll (event) {
      if (this.totalPage < this.productPage.pageIndex) return
      const windowHeight = window.innerHeight
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      )
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop
      const hasVerticalScrollbar =
        document.documentElement.scrollHeight >
        document.documentElement.clientHeight

      // 判断是否触底
      const isBottomReached = () => {
        const bottomOffset = 20 // 距离底部多少像素时认为触底
        return (
          documentHeight - (scrollTop + windowHeight) <= bottomOffset &&
          hasVerticalScrollbar
        )
      }
      if (isBottomReached()) {
        this.isMore = true
        this.productPage.pageIndex += 1
        await this.getData()
      }
    },
    async init () {
      this.productPage.pageIndex = 1
      await this.getData()
    },
    async getData () {
      const res = await getProductList(this.productPage)
      if (this.productPage.pageIndex > 1) {
        if (res.data.products && res.data.products?.length === 0) {
          this.isMore = false
        } else {
          if (res.data?.products) {
            this.productsList = [...this.productsList, ...res.data?.products]
          }
        }
      } else {
        this.productsList = res.data.products
      }
      // 总页数
      this.totalPage = res.data.totalPage
      // 相关分类
      if(!this.linkCategoriesAll.length){
        this.linkCategoriesAll = res.data.linkCategories?.map(item => {
          return {
            ...item,
            isActive: false
          }
        })
        if (this.linkCategoriesAll?.length > 12) {
          this.linkCategories = JSON.parse(JSON.stringify(this.linkCategoriesAll))
          this.linkCategories.splice(12, this.linkCategoriesAll.length)
          this.islinkbtn = true
        } else {
          this.linkCategories = JSON.parse(JSON.stringify(this.linkCategoriesAll || []))
          this.islinkbtn = false
        }
      }
      if(!this.brandListAll.length){
        // 品牌
        this.brandListAll = res.data?.extensions?.brands.map(item => {
          return {
            ...item,
            isActive: false
          }
        })
        if (this.brandListAll?.length > 12) {
          this.brandList = JSON.parse(JSON.stringify(this.brandListAll))
          this.brandList.splice(12, this.brandListAll.length)
          this.isbrandbtn = true
        } else {
          this.brandList = JSON.parse(JSON.stringify(this.brandListAll || []))
          this.isbrandbtn = false
        }
      }
      if (res.data?.products?.length === 0) {
        this.isMore = false
        this.isEmpty = true
      }

    },
    // 品牌
    handlebrandListOpenAll () {
      if (this.brandListAll.length > 12 && this.brandList.length > 12) {
        this.brandList = JSON.parse(JSON.stringify(this.brandListAll))
        this.brandList.splice(12, this.brandListAll.length)
      } else {
        this.brandList = JSON.parse(JSON.stringify(this.brandListAll))
      }
    },
    // 相关分类
    handlelinkOpenAll () {
      if (this.linkCategories.length > 12) {
        this.linkCategories = JSON.parse(JSON.stringify(this.linkCategoriesAll))
        this.linkCategories.splice(12, this.linkCategoriesAll.length)
      } else {
        this.linkCategories = JSON.parse(JSON.stringify(this.linkCategoriesAll))
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.sticky {
  position: sticky;
  left: 0;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}

.page {
  background-color: #ffffff;
  // height: 100vh;
}

.empty {
  background-color: #ffffff;
  height: 77vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0;

  img {
    width: 120px;
    height: 120px
  }

  .tigs {
    margin-top: 4px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
    line-height: 24px;
    text-align: center;
  }
}

.product-main {
  background-color: #ffffff;
  width: 375px;
  margin: 0 auto;
}

.product-main-warper {
  width: 343px;
  margin: 16px auto 0;
  overflow: hidden;
}

.product-box {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  height: 120px;

  .box-left {
    width: 120px;
    height: 100%;
    font-size: 0;
    background: #BDBDBD;
    border-radius: 14px 14px 14px 14px;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    overflow: hidden;
    margin-right: 16px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .tag {
      width: 42px;
      height: 42px;
      background: url("~@/assets/image/discounts-tag.png") 100% 100% no-repeat;
      background-size: cover;
      position: absolute;
      left: 6px;
      top: 0;

      .top {
        font-family: OPlusSans 3.0, OPlusSans 30;
        font-weight: bold;
        font-size: 11px;
        color: #FFFFFF;
        line-height: 24px;
        text-shadow: 0px 3px 6px rgba(235, 39, 0, 0.5);
        text-align: center;

        span {
          font-size: 17px;

        }
      }

      .bottom {
        margin-top: -6px;
        font-family: OPlusSans 3.0, OPlusSans 30;
        font-weight: 400;
        font-size: 11px;
        color: #FFFFFF;
        line-height: 16px;
        text-shadow: 0px 3px 6px rgba(235, 39, 0, 0.5);
        text-align: center;
      }
    }
  }

  .box-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 207px;
    height: 100%;

    .box-right-top {
      .brand {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 13px;
        color: #1A1A1A;
        line-height: 20px;
        text-align: left;
      }

      .title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #1A1A1A;
        line-height: 22px;
        height: 44px;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .box-right-main {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .tag {
        margin-left: 3px;
        padding: 0 2px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 11px;
        color: #E84935;
        line-height: 16px;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid rgba(232, 47, 47, 0.5);
      }
    }

    .box-right-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .price {
        .current-price {
          font-weight: 800;
          font-size: 18px;
          color: #F82207;
          line-height: 30px;
          text-align: left;
        }

        .original-price {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #B3B3B3;
          line-height: 18px;
          text-align: left;
          text-decoration: line-through;
        }
      }

      .add {
        width: 24px;
        height: 24px;
        font-size: 0;

        img {
          width: 100%;
          height: 100%
        }
      }
    }
  }
}

.more {
  text-align: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding-bottom: 10px;
}

.loading {
  text-align: center;
  padding-bottom: 10px;
}

.content {
  width: 100%;
  height: 100%;
  padding: 0 16px 90px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  overflow-y: auto; //出现水平滚动条

  &::-webkit-scrollbar {
    display: none;
  }

  .seat {
    height: 44px;
  }

  .title {
    margin-top: 24px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 24px;
  }

  .title:nth-child(2) {
    margin: 0;
  }

  .content-box {
    margin-top: 12px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    &::after {
      content: '';
      width: 143px;
    }

    .box-item {
      width: 143px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: nowrap; //溢出不换行
      word-break: break-all;
      padding: 6px 12px;
      box-sizing: border-box;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: #666666;
      line-height: 20px;
      text-align: center;
      background: #F8F8F8;
      border: 1px solid #F8F8F8;
      border-radius: 12px 12px 12px 12px;
      margin-bottom: 8px;
      // &:nth-child(odd){

      // }
      &.active {
        background: #F0FBDA;
        border: 1px solid #90D12E;
      }
    }
  }

  .openAll {
    width: 100%;
    text-align: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #999999;
    box-shadow: 0 0 100px #fff,
      /* 主阴影 */
      0 0 100px rgba(255, 255, 255, 1),
      /* 次阴影 */
      0 0 100px rgba(255, 255, 255, 1);
    /* 远离中心的阴影 */
  }

  .fixed-btn {
    position: fixed;
    background-color: #ffffff;
    left: 0;
    bottom: 0px;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 32px;

    .reset {
      width: 142px;
      height: 44px;
      background: #EEEEEE;
      border-radius: 8px 8px 8px 8px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #666666;
      line-height: 44px;
      text-align: center;
    }

    .confirm {
      width: 142px;
      height: 44px;
      background: #90D12E;
      border-radius: 12px 12px 12px 12px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
      line-height: 44px;
      text-align: center;
    }
  }
}
</style>