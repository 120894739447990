export default [
  {
    path: '/product/:id',
    name: 'Product',
    component: () => import('@/views/product/index.vue'),
  },
  {
    path: '/app/:id',
    name: 'App',
    component: () => import('@/views/product/index.vue'),
  },

]