<template>
  <div>
    <div class="sticky">
      <search :hotlist="hotlist" :isShow="true" @isAppTip="handleisAppTip" />
    </div>
    <div class="home-main">
      <!-- 轮播图 -->
      <div class="home-main-swipe" v-if="banner?.length">
        <van-swipe class="swipe-box" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item,index) in banner" :key="index">
            <div class="swipe-img">
              <img :src="`${imgUrl}${item.image}`" alt="" @click="handleSkipPath(item)" />
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- 导航栏 -->
      <div class="content-3" v-if="navList?.length">
        <div id="nav" @scroll="handleTouchMove">
          <ul class="nav__content" :class="[{ 'layout1': navList?.length > 5,'layout2': navList?.length <= 5,}]">
            <li class="scroll_item" v-for="(item,index) in navList" :key="index" @click="handleSkipPath(item)">
              <div class="box">
                <img :src="`${imgUrl}${item.image}`" alt="" />
                <div class="title">{{ item.name }}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="kingkong-dot" v-if="navList?.length > 10">
          <div class="item" :style="{ left: 'calc(' + percentage + ')' }"></div>
        </div>
        <!-- <div class="nav-progress" ref="navProgress">
          <div class="progress-active" ref="progressActive"></div>
        </div> -->
      </div>

    </div>
    <!-- 狂欢节- -->
    <div class="content-4">
      <!-- 广告位 -->
      <div class="content-bg" v-if="contentBg?.length">
        <van-swipe class="content-swipe" :autoplay="3000" :show-indicators="false" indicator-color="white">
          <van-swipe-item v-for="item in contentBg" :key="item.id">
            <img :src="`${imgUrl}${item.image}`" alt="" @click="handleSkipPath(item)" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="content-box"
        :style="{ background: `url(${imgUrl}/${activity?.activityBackImage}) no-repeat`,backgroundSize: '100% 100%' }">
        <div class="content-left">
          <van-swipe class="content-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="item in activity?.activityBanner" :key="item">
              <img :src="`${imgUrl}${item.image}`" @click="handleSkipPath(item)" />
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="content-right">
          <div class="content-rightTop" v-for="item in activity?.activityActivitys" :key="item.id">
            <img :src="`${imgUrl}${item.image}`" alt="" @click="handleSkipPath(item)" />
          </div>
        </div>
      </div>
    </div>
    <!-- 超值专场 -->
    <div class="content-5" v-if="session?.sessionActivitys?.length">
      <div class="title">{{ session?.title }} <span>{{ session?.remark }}</span></div>
      <div id="content-nav">
        <ul class="nav__content">
          <li class="scroll_item" v-for="item in session?.sessionActivitys" :key="item.id">
            <div class="box" @click="handleSkipPath(item)">
              <img :src="`${imgUrl}${item.image}`" alt="" />
              <div :style="{ background: item.bgm,color: item.bgm_en }">{{ item.title }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 专区标题 -->
    <div class="content-6" v-if="classifyFloor?.length" v-for="(item,index) in classifyFloor" :key="index">
      <div class="content-top" @click="handleSkipMorePath(item)">
        <div class="title">{{ item?.floorName }}<span>{{ item?.remark }}</span></div>
        <div class="text" v-if="item.floorImagePath"><span>{{ $t("更多") }}</span><van-icon name="arrow"
            color="#666666" />
        </div>
      </div>
      <div class="content-box">
        <ul class="nav__content">
          <li class="scroll_item" v-for="(it,idx) in item?.products" :key="idx" @click="handleSkipDetail(it.link)">
            <div class="box">
              <div v-if="it.discount > 0" class="tag">
                <div class="top"><span>{{ it.discount }}</span>%</div>
                <div class="bottom">OFF</div>
              </div>
              <img :src="`${getProductImageUrl(it.picturePath,400)}`" alt="" />
              <div class="name">{{ it.name }}</div>
              <div class="price">{{ formatNumber(it.price) }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 精選推薦 -->
    <div class="recommend">
      <div :class="['content-box',{ 'isclose': !isAppTip }]">
        <ul class="nav__content">
          <li :class="['scroll_item']" v-for="(item,idx) in navis" :key="idx" @click="handleSelect(item.id)">
            <div :class="[{ 'active': item.isActive }]">{{ item.name }}</div>
          </li>
        </ul>
      </div>
      <div v-if="recommendRealProducts?.length">
        <div class="recommend-goods">
          <div class="goods" v-for="(item,idx) in recommendRealProducts" :key="idx"
            @click="handleSkipDetail(item.link)">
            <div v-if="item.discount > 0" class="tag">
              <div class="top"><span>{{ item.discount }}</span>%</div>
              <div class="bottom">OFF</div>
            </div>
            <img :src="`${getProductImageUrl(item.picturePath,1024)}`" alt="" @error="handleImageError" />
            <div class="goods-title">{{ item.brandName }}</div>
            <div class="goods-text">{{ item.name }}</div>
            <div class="box-tag">
              <span class="tags" v-for="(item,index) in item?.tags" :key="index">{{ item.title }}</span>
            </div>
            <div class="goods-price">
              <div class="goods-price-left"><span class="pic">{{ formatNumber(item.price) }}</span></div>
              <div class="goods-price-right"><span class="pic" v-if="item.price < item.oldPrice">{{
                formatNumber(item.oldPrice)
                  }}</span>
              </div>
            </div>
          </div>
        </div>
        <van-loading v-if="isMore && !isEmpty" size="13px" class="loading">{{ $t("加载中") }}...</van-loading>
        <div v-if="isEmpty" class="more">{{ $t("沒有更多啦") }}~</div>
      </div>
    </div>

    <div class="station"></div>
    <!-- 客服 -->
    <!-- <customerService ref="customerService"></customerService> -->
    <!-- 广告弹窗 -->
    <advertPop ref="advertPopRef"></advertPop>
    <!-- 登陆入口 -->
    <div class="entrance" v-if="islogin">
      <div class="box">
        <span class="text">{{ $t("登入賬號逛RIth商城") }}</span>
        <div class="login-btn" @click="handleLogin">{{ $t('登入') }}</div>
      </div>
    </div>
    <!-- 是否有待付款支票订单 -->
    <div class="order-prompt" v-if="!orderInfo.canUseCheck">
      <div class="box">
        <span class="text">{{ orderInfo.textCheck }}</span>
        <span class="text-right" @click="handeleOrderprompt">{{ $t("如何操作") }}<van-icon name="question-o" color="#7BB227"
            size="16px" style="margin-left: 4px" /></span>
      </div>
    </div>
    <popup ref="popup" :isCancel="false" :popupShow="isPrompt" :popupTitle="popupTitle"
      :popupContent="orderInfo.chequeAddress" @cancelPopup="handeleCancelPopup" @confirmPopup="handeleConfirmPopup">
    </popup>
    <!-- 新客专属 -->
    <div v-if="activityObj.isshow" :class="['activityfloat',{ 'acitve': isFloatImg }]">
      <div class="close" @click="handleClock">
        <img src="@/assets/image/close3.png" alt="" srcset="">
      </div>
      <div @click="handleNext()" class="float-img">
        <img :src="`${imgUrl}${activityObj.enTryPicUrl}`" @error="handleImageError">
      </div>
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
import search from "@/components/search/index"
import tabbar from "@/components/tabbar/index"
import customerService from "@/components/customerService/index"
import advertPop from "@/components/advertPop/index"
import popup from "@/components/popup/index"
import BScroll from '@better-scroll/core'
import { getIndexRith, getExtendPhone, getRecommend } from "@/api/home"
import Config from "@/utils/config.js"
import { getToken, rmoveToken } from "@/utils/token"
import { gethotWord } from "@/api/search"
import Mixins from "@/utils/mixins"
export default {
  mixins: [Mixins],
  components: {
    tabbar,
    search,
    customerService,
    advertPop,
    popup
  },
  data () {
    return {
      bs: null,
      imgUrl: Config.imgUrl,
      banner: null,
      navList: null,
      contentBg: null,
      adverSpace: null,
      activity: null,
      session: null,
      classifyFloor: null,
      navis: null,
      recommendRealProducts: null,
      defaultImg: require('@/assets/image/e-Rith.png'),
      islogin: false,
      isPrompt: false,
      popupTitle: this.$t('您有以下幾種方式進行支票匯款'),
      orderInfo: {
        canUseCheck: true,
        chequeAddress: null,
        textCheck: null
      },
      isFloatImg: false,
      activityObj: {
        isshow: false,
        enTryPicUrl: ''
      },
      hotlist: [],
      parameter: {
        pageIndex: 1,
        pageSize: 10,
        type: null
      },
      totalPage: null,
      isMore: false,
      isEmpty: false,
      scrollFn: this.throttle(this.handleScroll, 100),
      isAppTip: true,
      percentage: "0%"
    }
  },
  mounted () {
    this.getData()
    this.gethotWordData()
    this.islogin = !getToken()
    if (!this.islogin) {
      this.$store.dispatch("shopCart/getCartCount")
    }
    window.addEventListener("scroll", this.scrollFn, true)
  },
  methods: {
    // init () {
    //   this.bs = new BScroll(this.$refs.scrollX, {
    //     probeType: 3,
    //     scrollX: true,
    //     click: true
    //   })

    //   // 最大横向滚动距离
    //   let maxScrollX = this.bs.maxScrollX

    //   this.bs.on('scroll', (data) => {
    //     let x = Number(data?.x)
    //     // 计算比例
    //     let ratio = this.toFixedFun(x / maxScrollX, 100)
    //     let activeRatio = this.toFixedFun(this.$refs.navProgress.clientWidth * ratio, 100)
    //     this.$refs.progressActive.style.width = activeRatio ? activeRatio + 'px' : '1px'
    //   })
    // },
    // 获取是否关闭跳转
    handleisAppTip (data) {
      this.isAppTip = data
    },
    // 如何操作
    handeleOrderprompt () {
      this.isPrompt = true
    },
    handeleCancelPopup () {
      this.isPrompt = false
    },
    handeleConfirmPopup () {
      this.isPrompt = false
    },
    toFixedFun (data, len) {
      return Math.floor(data * len) / len
    },
    open () {
      this.$refs.advertPopRef.open()
    },
    async gethotWordData () {
      const res = await gethotWord()
      if (res.resultID === 1200) {
        this.hotlist = res.data.map(item => item.keyWord)
      }
    },
    async getData () {
      const res = await getIndexRith({ type: 2 })
      if (res.isSuccess) {
        // 轮播图
        this.banner = res.data?.banner
        // 金刚区导航栏
        this.navList = res.data?.kong
        // this.navList.splice(3, 5)
        // 广告位
        this.contentBg = res.data?.adverSpace
        // 待付款支票订单信息
        this.orderInfo = {
          canUseCheck: res.data?.canUseCheck,
          chequeAddress: res.data?.chequeAddress,
          textCheck: res.data?.textCheck,
        }
        this.activityObj = {
          isshow: res.data.newCus == 1 ? true : res.data.newCus == 2 ? true : false,
          enTryPicUrl: res.data?.enTryPicUrl
        }
      }
      const data = await getExtendPhone({ type: 2 })
      if (data.isSuccess) {
        // 狂欢节
        this.activity = data.data?.activity
        // 超值专区
        this.session = data.data?.session
        // 专场
        this.classifyFloor = data.data?.classifyFloor
      }
      const reulst = await getRecommend({
        pageIndex: 1,
        pageSize: 1,
      })
      if (reulst.isSuccess) {
        this.navis = reulst.data?.navis.map((item, idx) => {
          if (idx === 0) {
            return {
              ...item,
              isActive: true
            }
          } else {
            return {
              ...item,
              isActive: false
            }
          }
        })
        this.handleSelect(this.navis[0]?.id)
      }
    },
    async getProductData () {
      const res = await getRecommend(this.parameter)
      if (this.parameter.pageIndex > 1) {
        if (res.data.recommendRealProducts && res.data.recommendRealProducts?.length === 0) {
          this.isMore = false
        } else {
          if (res.data?.recommendRealProducts) {
            this.recommendRealProducts = [...this.recommendRealProducts, ...res.data?.recommendRealProducts]
          }
        }
      } else {
        this.recommendRealProducts = res.data.recommendRealProducts
      }
      // 总页数
      this.totalPage = res.data.totalPage

      if (res.data?.recommendRealProducts?.length === 0) {
        this.isMore = false
        this.isEmpty = true
      }
    },
    async handleSelect (data) {
      this.navis.forEach(item => {
        if (item.id === data) {
          item.isActive = true
        } else {
          item.isActive = false
        }
      })
      this.recommendRealProducts = []
      this.parameter.type = data
      this.parameter.pageIndex = 1
      await this.getProductData()
    },
    // 跳转详情
    handleSkipDetail (data) {
      this.$router.push(`/product/${data}`)
    },
    handleSkipPath (data) {
      if (data?.path.trim() === "") {
        return
      }
      if (data?.path === 'All Categories' || data?.path === 'All categories') {
        this.$router.push(`/allAssort`)
        return
      }
      const isFontIcon = data?.path.substring(0, 1) == "/" ? true : false
      this.$router.push(`${!isFontIcon ? '/' : ''}${data?.path}`)
    },
    handleSkipMorePath (data) {
      if (data?.floorImagePath.trim() === "") {
        return false
      }
      const isFontIcon = data?.floorImagePath.substring(0, 1) == "/" ? true : false
      this.$router.push(`${!isFontIcon ? '/' : ''}${data?.floorImagePath}`)
    },
    handleImageError (e) {
      e.target.src = this.defaultImg // 只需要将此路径换成对应的默认图片路径即可
    },
    handleLogin () {
      location.href = '/login' + `?redirect=${this.$router.currentRoute.value.fullPath}`
    },
    handleClock () {
      this.isFloatImg = true
    },
    handleNext () {
      this.$router.push(`/newCustomers`)
    },
    async handleScroll (event) {
      if (this.totalPage < this.parameter.pageIndex) return
      const windowHeight = window.innerHeight
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      )
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop
      const hasVerticalScrollbar =
        document.documentElement.scrollHeight >
        document.documentElement.clientHeight

      // 判断是否触底
      const isBottomReached = () => {
        const bottomOffset = 20 // 距离底部多少像素时认为触底
        return (
          documentHeight - (scrollTop + windowHeight) <= bottomOffset && hasVerticalScrollbar
        )
      }
      if (isBottomReached()) {
        this.isMore = true
        this.parameter.pageIndex += 1

        await this.getProductData()
      }
    },
    handleTouchMove () {
      // 计算滑动距离
      const e = document.querySelector("#nav")
      const box2 = document.querySelector(".content-3")

      const percentage =
        (e.scrollLeft / (e.scrollWidth - box2.offsetWidth)) * 50

      if (percentage > 50) {
        this.percentage = 50 + "%"
        return
      }
      if (percentage < 0) {
        this.percentage = 0 + "%"
        return
      }
      this.percentage = percentage + "%"
    },
  },
  beforeDestroy () {
    // this.bs.destroy()
    window.removeEventListener('scroll', this.scrollFn, true)
  },
  beforeUnmount () {
    // this.bs.destroy()
    window.removeEventListener('scroll', this.scrollFn, true)
  },

}
</script>

<style lang="scss" scoped>
.sticky {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 10;
}

.home-main {
  background: #ffffff;
}

// 轮播图
.home-main-swipe {
  width: 352px;
  // padding: 0 12px;
  margin: 0 auto 20px;


  .van-swipe-item {
    overflow: hidden;
  }

  .swipe-box {
    width: 100%;
    height: 144px;
    overflow: hidden;
    border-radius: 12px 12px 12px 12px;
    background: #ffffff;
  }

  .swipe-img {
    width: 100%;
    height: 100%;
    font-size: 0;
    overflow: hidden;

    img {
      border-radius: 12px 12px 12px 12px;
      width: 100%;
      height: 100%;
    }
  }



  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dioc {
      width: 6px;
      height: 6px;
      background-color: #ebedf0;
      border-radius: 100%;
      opacity: 0.3;
      transform: all 0.6s;
    }

    .isactive {
      width: 10px;
      height: 6px;
      border-radius: 2px;
      background-color: #ffffff;
      opacity: 1;
    }
  }
}

// 导航栏
.content-3 {
  position: relative;
  width: 350px;
  overflow: hidden;
  margin: 0 auto;

  .kingkong-dot {
    width: 16px;
    height: 4px;
    background-clip: content-box;
    /* border-left: 8px solid rgba(144, 209, 46, 0.20);
  border-right: 8px solid rgba(144, 209, 46, 0.20); */
    position: absolute;
    bottom: 4px;
    border-radius: 5px 5px 5px 5px;
    left: 50%;
    transform: translate(-50%, 0);
    background: rgba(144, 209, 46, 0.2);

    .item {
      position: absolute;
      left: -4px;
      width: 8px;
      height: 4px;
      background: #90d12e;
      bottom: 0px;
      border-radius: 4px 4px 4px 4px;
      /* transition: left 0.1s; */
    }
  }

}

#nav {
  width: 100%;
  background-color: #fff;
  // height: 170px;
  padding: 8px 0px;
  display: flex;
  flex-flow: column wrap;
  list-style-type: none;
  overflow-x: scroll;


  &::-webkit-scrollbar {
    display: none;
  }

  .layout1 {
    height: 150px;
    margin: 0px 0 16px 0;
    display: inline-flex;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .layout2 {
    height: 68px;
    margin: 0px 0 16px 0;
    display: inline-flex;
  }

  li {
    width: 70px;
    height: 68px;

    .box {
      width: 100%;
      height: 100%;
      text-align: center;
      flex: 1 0 auto;
      font-size: 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        width: 40px;
        height: 40px;
      }

      .title {
        width: 100%;
        margin-top: 8px;
        font-weight: 500;
        font-size: 12px;
        color: #666666;
        line-height: 20px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }
  }
}






// .nav-progress {
//   margin: 0 auto;
//   width: 20px;
//   height: 4px;
//   background: #EEEEEE;
//   border-radius: 3px 3px 3px 3px;

//   .progress-active {
//     width: 1px;
//     height: 4px;
//     background: #90D12E;
//     border-radius: 2px 2px 2px 2px;
//     transition: all 0.1s;
//   }
// }

// 狂欢节
.content-4 {
  width: 375px;
  margin: 0 auto;
  font-size: 0;

  .content-bg {
    width: 100%;
    height: 62px;

    .content-swipe {
      width: 100%;
      height: 100%;

      img {

        width: 100%;
        height: 100%;
      }
    }
  }

  .content-box {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content-left {
      width: 172px;
      height: 240px;

      .content-swipe {
        width: 100%;
        height: 100%;
        border-radius: 8px 8px 8px 8px;

        img {

          width: 100%;
          height: 100%;
        }
      }
    }

    .content-right {
      width: 172px;
      height: 240px;

      .content-rightTop {
        margin-top: 8px;
        width: 100%;
        height: 116px;
        font-size: 0;

        &:first-child {
          margin-top: 0;
        }
      }



      // .content-rightBottom {
      //   margin-top: 8px;
      //   width: 100%;
      //   height: 116px;
      // }
    }
  }
}

// 超值专场
.content-5 {
  width: 351px;
  padding: 12px 8px 14px 8px;
  box-sizing: border-box;
  margin: 16px auto 0;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;

  .title {
    font-family: OPlusSans 3.0, OPlusSans 30;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 15px;
    text-align: left;

    span {
      margin-left: 6px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.26);
      line-height: 12px;
      text-align: left;
    }
  }

  #content-nav {
    width: 335px;
    margin: 8px auto 0;
    overflow: hidden; //超出隐藏
    white-space: nowrap; //超出不换行
    overflow-x: auto; //出现水平滚动条

    &::-webkit-scrollbar {
      display: none;
    }

    .nav__content {
      display: inline-flex;

      li {
        width: 80px;
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }

        .box {
          width: 100%;
          height: 100%;
          text-align: center;
          flex: 1 0 auto;
          font-size: 0;
          display: flex;
          align-items: center;
          flex-direction: column;

          img {
            width: 80px;
            height: 80px;
          }

          div {
            margin-top: -10px;
            min-width: 60px;
            max-width: 100px;
            padding: 0 6px;
            box-sizing: border-box;
            height: 20px;
            background: #E2F0CC;
            border-radius: 10px 10px 10px 10px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 12px;
            color: #7DCB08;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
  }
}

// 专区标题
.content-6 {
  width: 351px;
  margin: 16px auto 0;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  padding: 12px 8px 14px;
  box-sizing: border-box;

  .content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      width: 290px;
      font-family: OPlusSans 3.0, OPlusSans 30;
      font-weight: bold;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 15px;
      text-align: left;

      span {
        margin-left: 6px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.26);
        line-height: 12px;
        text-align: left;
      }
    }

    .text {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 11px;
      color: #666666;
      line-height: 16px;
    }
  }

  .content-box {
    width: 343px;
    margin: 8px auto 0;
    overflow: hidden; //超出隐藏
    white-space: nowrap; //超出不换行
    overflow-x: auto; //出现水平滚动条

    &::-webkit-scrollbar {
      display: none;
    }

    .nav__content {
      display: inline-flex;




      li {
        width: 109px;
        margin-right: 8px;
        padding: 0 6px 6px;
        box-sizing: border-box;
        overflow: hidden;
        border: 1px solid #F8F8F8;
        border-radius: 8px 8px 8px 8px;
        position: relative;

        &:last-child {
          margin-right: 0;
        }

        .box {
          width: 100%;
          height: 100%;
          font-size: 0;
          display: flex;
          align-items: center;
          flex-direction: column;
          position: relative;

          .tag {
            width: 42px;
            height: 42px;
            background: url("~@/assets/image/discounts-tag.png") 100% 100% no-repeat;
            background-size: cover;
            position: absolute;
            left: -4px;
            top: 0;

            .top {
              font-family: OPlusSans 3.0, OPlusSans 30;
              font-weight: bold;
              font-size: 11px;
              color: #FFFFFF;
              line-height: 24px;
              text-shadow: 0px 3px 6px rgba(235, 39, 0, 0.5);
              text-align: center;

              span {
                font-size: 17px;

              }
            }

            .bottom {
              margin-top: -6px;
              font-family: OPlusSans 3.0, OPlusSans 30;
              font-weight: 400;
              font-size: 11px;
              color: #FFFFFF;
              line-height: 16px;
              text-shadow: 0px 3px 6px rgba(235, 39, 0, 0.5);
              text-align: center;
            }
          }

          img {
            width: 109px;
            height: 109px;
          }

          .name {
            margin-top: 4px;
            width: 100%;
            white-space: nowrap;
            /* 不换行 */
            overflow: hidden;
            /* 隐藏溢出内容 */
            text-overflow: ellipsis;
            /* 溢出时显示省略号 */
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.9);
            line-height: 20px;
            text-align: left;
          }

          .price {
            margin-top: 6px;
            width: 100%;
            font-family: PingFang SC, PingFang SC;
            font-weight: 800;
            font-size: 14px;
            color: #F82207;
            line-height: 22px;
            text-align: left;
          }
        }
      }
    }
  }
}

// 精品推荐
.recommend {
  width: 375px;
  margin: 8px auto 0;

  .content-box {
    padding: 8px 12px;
    box-sizing: border-box;
    background: #f8f8f8;
    position: sticky;
    top: 88px;
    left: 0;

    &.isclose {
      top: 44px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .nav__content {
      display: inline-flex;

      li {
        margin-right: 8px;
        box-sizing: border-box;

        div {
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
          border-radius: 12px 12px 12px 12px;
          padding: 3px 12px;
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          font-size: 15px;
          color: #000000;
          line-height: 24px;
          text-align: center;
          transition: all 0.6s;

          &.active {
            color: #90D12E;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }


    }
  }

  .recommend-goods {
    padding: 0 12px;
    box-sizing: border-box;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;



    .goods {
      width: 170px;
      padding-bottom: 12px;
      box-sizing: border-box;
      background: #FFFFFF;
      border-radius: 8px;
      margin-bottom: 8px;
      overflow: hidden;
      font-size: 0;
      border: 0;
      position: relative;

      .tag {
        width: 42px;
        height: 42px;
        background: url("~@/assets/image/discounts-tag.png") 100% 100% no-repeat;
        background-size: cover;
        position: absolute;
        left: 3px;
        top: 0;

        .top {
          font-family: OPlusSans 3.0, OPlusSans 30;
          font-weight: bold;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 24px;
          text-shadow: 0px 3px 6px rgba(235, 39, 0, 0.5);
          text-align: center;

          span {
            font-size: 17px;

          }
        }

        .bottom {
          margin-top: -6px;
          font-family: OPlusSans 3.0, OPlusSans 30;
          font-weight: 400;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 16px;
          text-shadow: 0px 3px 6px rgba(235, 39, 0, 0.5);
          text-align: center;
        }
      }

      img {
        width: 170px;
        height: 170px;
        border: 0;
      }

      .goods-title {
        margin-top: 12px;
        width: 100%;
        white-space: nowrap;
        /* 不换行 */
        overflow: hidden;
        /* 隐藏溢出内容 */
        text-overflow: ellipsis;
        /* 溢出时显示省略号 */
        padding: 0 8px;
        box-sizing: border-box;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 13px;
        color: #1A1A1A;
        line-height: 20px;
        text-align: left;
      }

      .goods-text {
        margin-top: 4px;
        width: 100%;
        height: 40px;
        padding: 0 8px;
        box-sizing: border-box;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 13px;
        color: #1A1A1A;
        line-height: 20px;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .box-tag {
        margin-top: 4px;
        min-height: 20px;
        padding: 0 8px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .tags {
          margin-left: 3px;
          padding: 0 2px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 11px;
          color: #E84935;
          line-height: 16px;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid rgba(232, 47, 47, 0.5);

          &:first-child {
            margin: 0;
          }
        }

      }

      .goods-price {
        width: 100%;
        margin-top: 3px;
        padding: 0 8px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .goods-price-left {
          .unit {
            font-family: PingFang SC, PingFang SC;
            font-weight: 800;
            font-size: 14px;
            color: #F82207;
            line-height: 24px;
            text-align: left;
          }

          .pic {
            font-family: PingFang SC, PingFang SC;
            font-weight: 800;
            font-size: 14px;
            color: #F82207;
            line-height: 24px;
            text-align: left;
          }
        }

        .goods-price-right {
          .unit {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #B3B3B3;
            line-height: 18px;
            text-align: left;
            text-decoration: line-through;
          }

          .pic {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #B3B3B3;
            line-height: 18px;
            text-align: left;
            text-decoration: line-through;
          }
        }
      }
    }

    &:after {
      content: "";
      width: 170px;
    }
  }
}

.station {
  height: 100px;
}

.entrance {
  position: fixed;
  left: 50%;
  bottom: 55px;
  transform: translate(-50%, 0%);
  width: 375px;

  .box {
    margin: 0 auto;
    width: 352px;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 23px 23px 23px 23px;
    margin: 0 auto;
    padding: 6px 6px 6px 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 22px;
    }

    .login-btn {
      width: 68px;
      height: 30px;
      background: #90D12E;
      border-radius: 30px 30px 30px 30px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 30px;
      text-align: center;
    }
  }
}

.order-prompt {
  position: fixed;
  left: 50%;
  bottom: 50px;
  width: 375px;
  transform: translate(-50%, 0%);

  .box {
    width: 375px;
    background: rgba(0, 0, 0, 0.7);
    padding: 6px 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 13px;
      color: #F8F8F8;
      line-height: 20px;
    }

    .text-right {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: #7BB227;
      display: flex;
      align-items: center;
    }
  }
}

.activityfloat {
  position: fixed;
  right: 5px;
  bottom: 141px;
  width: 54px;
  transition: all 0.3s;
  overflow: hidden;

  &.acitve {
    right: -100px;
  }

  .close {
    width: 100%;
    text-align: right;
    font-size: 0;

    img {
      display: inline-block;
      width: 16px;
      height: 16px;
    }
  }

  .float-img {
    font-size: 0;
    width: 54px;
    height: 54px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.more {
  text-align: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding-bottom: 10px;
}

.loading {
  text-align: center;
  padding-bottom: 10px;
}
</style>