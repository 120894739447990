<template>
  <van-popup v-model:show="isShow" position="bottom" :style="{
    height: '70vh',
    borderRadius: '16px 16px 0 0',
    overflow: 'hidden'
  }" @closed="handleClose">
    <div class="spec-menu-content Specificationdialog">
      <div class="title">
        <div class="goods-brand">
          <div>
            <img :src="getProductImageUrl(defaultSelectGoods?.picturePath,400)" />
          </div>
          <div class="price">
            <div class="price-text" v-if="isAuth.isAuth">
              <!-- 展示折扣价 -->
              <span style="display: table-cell; color: red">
                <span style="font-size: 16px; margin-bottom: 8px"> </span>
                <span>{{
                  formatNumber(defaultSelectGoods?.discountPrice)
                  }}</span>
              </span>
              <!-- 展示原价 -->
              <div class="original-price" v-if="defaultSelectGoods?.isDiscount">
                {{ formatNumber(defaultSelectGoods?.price) }}
              </div>
            </div>
            <div class="price-text" v-else>
              <div>{{ isAuth.text }}</div>
            </div>

            <div class="goodsname">{{ selectSpecification.name }}</div>
            <div class="unit">
              <span>{{ $t("单位") }}:{{ defaultSelectGoods?.unit }}</span>
              <span v-if="defaultSelectGoods?.containerQuantity"> | </span>{{
                defaultSelectGoods?.containerQuantity
                  ? $t("每箱") +
                  ":" +
                  defaultSelectGoods?.containerQuantity +
                  defaultSelectGoods?.unit
                  : ""
              }}<span> </span>
            </div>
            <!-- <div class="Select">
              <span v-if="defaultSelectGoods.standard">选中：</span>
              <span>{{ defaultSelectGoods.standard }}</span>
            </div> -->
          </div>
        </div>
        <div class="close">
          <img @click="handleClose" src="@/assets/icons/ic_Close@2x.png" alt="" />
        </div>
      </div>
      <div class="goods-tab" v-if="SelectedList.length > 1">
        <!-- <div class="tabs">
          <div @click.stop="handleChangeTab('1')" ref="allSpecification">
            全部规格
          </div>
          <div @click.stop="handleChangeTab('2')" ref="selectSpecification">
            已选（{{ length }}）
          </div>
          <div class="bottom-bor" ref="bottomborder"></div>
        </div> -->

        <!-- <div class="all-tabs" @click="applyAllFun">
          <div style="margin-right: 1.0667vw">购买全部规格</div>
          <div></div>
        </div> -->
        <div class="Specificationselect-title">{{ $t("商品規格") }}</div>
      </div>

      <div class="Specificationselect">
        <div class="items" v-for="(item,index) in specificationList" :key="item.searchProductId">
          <div :class="[
            'left',
            { SpecificationActive: activeIndex === item.searchProductId }
          ]" @click="handleSpecification(item,index)">
            <div>
              <p v-if="item.standard" style="font-size: 14px; color: rgba(0, 0, 0, 0.9)">
                {{ item.standard }}
              </p>
              <img class="phone-check" v-if="activeIndex == item.searchProductId" src="@/assets/icons/check.png"
                alt="" />
            </div>

            <div class="Sold-out" v-if="item.stock === 0">
              {{ $t("售罄") }}
            </div>
            <div class="Sold-out" v-if="item.isBuyLimited">
              {{ $t("限购") }}
            </div>
          </div>

          <!-- <div
            class="arrive"
            @click="getNoticeofArrival(item)"
            v-if="item.stock === 0"
          >
            到货通知
          </div> -->
          <div class="right">
            <div>
              <img v-if="item.amount === 0" src="@/assets/icons/forbidden.png" alt="" />
              <img v-else @click="handeRemoveAmount(item)" src="@/assets/icons/remove.png" alt="" />
            </div>
            <div style="line-height: 1">
              <input class="input-number" v-model.number="item.amount" @input="getIsAmount(item)" type="text" />
            </div>
            <div>
              <img v-if="item.amount === item.stock" src="@/assets/icons/addforbidden.png" alt="" />
              <img @click="handeAddAmount(item)" v-else src="@/assets/icons/add.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="confirm">
        <div class="confirm-select">
          <div class="confirm-text">
            {{ $t("已選") }} {{ standardLength
            }}{{ $t("件商品") }}
            <span>（{{ length }}{{ $t("個商品規格") }}）</span>
          </div>
          <div class="confirm-price">{{ formatNumber(totalprice) }}</div>
        </div>
        <div v-if="isAuth.btnType === 1" style="display: flex; align-items: center;justify-content: space-between;">
          <div class="confirmbtn" @click="handleConfirm" v-if="isAuth.isAuth">
            {{ $t("立即購買") }}
          </div>
          <div class="confirmbtn" @click="handleConfirm" v-else>
            {{ isAuth.text }}
          </div>
          <div class="addCart" @click="handleAddToCart">{{ $t("加入購物車") }}</div>
        </div>
        <div v-else-if="isAuth.btnType === 2">
          <div class="confirmbtn1" @click="handleConfirm" v-if="isAuth.isAuth">
            {{ $t("立即購買") }}
          </div>
          <div class="confirmbtn1" @click="handleConfirm" v-else>
            {{ isAuth.text }}
          </div>
        </div>
        <div v-else class="addCart1" @click="handleAddToCart">{{ $t("加入購物車") }}</div>
      </div>
      <!-- <div class="all_bg" v-if="allShow"></div> -->
      <!-- 购买全部规格 -->
      <!-- <div class="buy_all" v-if="allShow">
        <div class="titles">购买全部规格</div>
        <div class="right">
          <div @click="removeAllTotal">
            <img src="@/assets/icons/remove.png" alt="" />
          </div>
          <div style="font-size: 3.4667vw">
            <input
              style="
                width: 11.2vw;
                text-align: center;
                background-color: #f8f8f8;
              "
              v-model.number="allTotal"
              type="text"
            />
          </div>
          <div @click="addAllTotal">
            <img src="@/assets/icons/add.png" alt="" />
          </div>
        </div>
        <div class="buy_all-btn">
          <div class="buy_all-cancelbtn" @click="allShow = false">取消</div>
          <div class="buy_all-confirmbtn" @click="handleAllConfirm">确定</div>
        </div>
      </div> -->
    </div>
  </van-popup>
</template>
<script>
import Mixins from "@/utils/mixins"
import { getProductSpecification } from "@/api/productdetails.js"
import { addShoppingCart } from "@/api/shopCart.js"
export default {
  name: "specificationDialog",
  data: () => ({
    selectSpecification: {},
    SelectedList: [], //保存的所有规格列表
    specificationList: [],
    defaultSelectGoods: {},
    allShow: false,
    activeIndex: 0,
    length: 0,
    allTotal: 0,
    isShow: false,
    totalprice: 0,
    standardLength: 0
  }),
  props: {
    show: {
      type: Boolean,
      default: false
    },
    searchProductId: {
      type: String,
      default: ""
    },
    tagtype: {
      type: String,
      default: ""
    },
    isAuth: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    specificationList: {
      deep: true,
      handler (val) {
        this.totalprice = this.getTotalprice()
        this.getLength()
        console.log(val)
      }
    },
    async show () {
      this.isShow = this.show
      await this.getList()
      console.log(this.searchProductId)
      this.activeIndex = this.specificationList[0].searchProductId
      if (
        this.specificationList.length === 1 &&
        this.specificationList[0].stock > 0
      ) {
        this.specificationList[0].amount = 1
      }
    },
    searchProductId () {
      this.defaultSelectGoods = this.specificationList.find(
        item => item.searchProductId == this.searchProductId
      )
      this.activeIndex = this.searchProductId
    }
  },
  mixins: [Mixins],
  async created () { },

  async mounted () {
    // this.$nextTick(() => {
    //   if (this.SelectedList.length > 1) {
    //     this.$refs.bottomborder.style.width =
    //       this.$refs.allSpecification.offsetWidth + "px";
    //     this.$refs.bottomborder.style.left =
    //       this.$refs.allSpecification.offsetLeft + "px";
    //   }
    // });
  },
  methods: {
    getTotalprice () {
      const total = this.specificationList?.reduce(
        (prve, next) => prve + next.amount * next.discountPrice,
        0
      )
      return total
    },
    handleClose () {
      this.$emit("handleClose")
    },
    // 到货通知
    getNoticeofArrival (item) {
      this.$store.dispatch("addStockRegistration", {
        goods: [
          {
            searchProductId: item.searchProductId,
            count: 1
          }
        ],
        isDeleteShoppingCart: false
      })
    },
    // 减少全部规格
    removeAllTotal () {
      if (this.allTotal === 0) return
      this.allTotal -= 1
    },
    // 增加全部规格
    addAllTotal () {
      console.log(this.allTotal)
      this.allTotal += 1
    },
    // 全部规格确定
    handleAllConfirm () {
      const res = this.specificationList.find(
        item => this.allTotal > item.stock
      )
      if (res) {
        return this.showMsg(
          `规格：${res.standard} 的库存为${res.stock}${res.unit}，无法全部购买`
        )
      }
      this.specificationList.forEach(item => {
        item.amount = this.allTotal
      })
      this.allShow = false
      this.getLength()
      console.log(res)
    },
    //确定
    handleConfirm () {
      const res = this.specificationList.filter(el => el.amount > 0)
      if (res.length === 0) {
        return this.$toast(this.$t('請先選擇購買商品'))
      }
      this.$emit("handleConfirm", res)
    },
    // 加入购物车
    async handleAddToCart () {
      const data = this.specificationList?.filter(el => this.activeIndex === el.searchProductId)
      if (data.length) {
        const goods = []
        data.forEach(item => {
          if (item.amount > 0) {
            goods.push({
              count: item.amount,
              searchProductId: item.searchProductId
            })
          }
        })
        if (goods.length) {
          const res = await addShoppingCart({
            isDeleteStockRegistration: false,
            goods
          })
          if (res.resultID === 1200) {
            this.$toast(this.$t('加購成功'))
            this.$store.dispatch("shopCart/getCartCount")
            this.$emit("handleClose")
          }
        } else {
          this.$toast(this.$t('至少選擇壹個商品'))
        }

      } else {
        this.$toast(this.$t('至少選擇壹個商品'))
      }

    },
    // 获取已选长度
    getLength () {
      const res = this.specificationList.filter(el => el.amount > 0)
      this.length = res.length
      this.standardLength = this.specificationList.reduce(
        (prve, next) => Number(prve) + Number(next.amount),
        0
      )
    },
    // 数量判断
    getIsAmount (item, type) {
      if (item.amount % item.sellPartCount != 0) {
        if (type == "1") {
          item.amount -= item.sellPartCount / 2
          item.amount =
            item.sellPartCount * Math.floor(item.amount / item.sellPartCount)
        } else {
          item.amount += item.sellPartCount
          item.amount =
            item.sellPartCount * Math.floor(item.amount / item.sellPartCount)
        }
      }
      if (item.amount > item.stock && item.isBuyLimited) {
        item.amount = item.stock
        return this.$toast(`${this.$t('限購')}${item.stock}${item.unit}`)
      } else if (item.amount > item.stock) {
        item.amount = item.stock
        return this.$toast(this.$t('庫存不足'))
      }
    },
    // 减少数量
    handeRemoveAmount (item) {
      if (item.amount <= 0) {
        return
      }

      item.amount -= 1
      this.getIsAmount(item, "1")
      this.getLength()
    },
    // 添加数量
    handeAddAmount (item) {
      item.amount += 1
      this.getIsAmount(item)
      this.getLength()
    },
    // 点击切换规格
    handleSpecification (item, index) {
      this.activeIndex = item.searchProductId
      this.defaultSelectGoods = this.specificationList.find(
        items => items.searchProductId === item.searchProductId
      )
    },
    async getList () {
      const res = await getProductSpecification({
        id: this.searchProductId,
        tagtype: this.tagtype
      })
      this.selectSpecification = res.data
      this.specificationList = res.data.list
      this.SelectedList = res.data.list //保存用于tab切换
      this.defaultSelectGoods = res.data.list[0]
    },
    handleChangeTab (index) {
      this.$refs.bottomborder.style.transition = "ease-out left 0.3s"
      if (index === "1") {
        this.specificationList = this.SelectedList

        this.$refs.bottomborder.style.left =
          this.$refs.allSpecification.offsetLeft + "px"
        this.$refs.bottomborder.style.width =
          this.$refs.allSpecification.offsetWidth + "px"
      }
      if (index === "2") {
        this.specificationList = this.specificationList.filter(
          item => item.amount > 0
        )
        this.$refs.bottomborder.style.left =
          this.$refs.selectSpecification.offsetLeft + "px"
        this.$refs.bottomborder.style.width =
          this.$refs.selectSpecification.offsetWidth + "px"
      }
    },
    closeDialog () {
      // this.$parent.closeDialog();
      this.$emit("closeDialog")
    },
    applyAllFun () {
      this.allShow = true
    }
  }
}
</script>
<style scoped lang="scss">
.Specificationdialog .title {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 20px 16px;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
}

.Specificationdialog .title img {
  width: 24px;
}

.Specificationdialog {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 201;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
}

.Specificationdialog .goods-brand {
  display: flex;

  img {
    width: 112px;
    height: 100%;
  }
}

.Specificationdialog .goods-brand .price {
  margin-left: 8px;
}

.Specificationdialog .goods-brand .price .price-text {
  margin-top: 8px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 800;
  font-size: 22px;
  color: #e84935;
  line-height: 24px;

  .original-price {
    text-decoration: line-through;
    font-weight: 400;
    font-size: 13px;
    color: #999999;
  }
}

.Specificationdialog .goods-brand .unit {
  color: #000000;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 4px;
  margin-bottom: 4px;
}

.Specificationdialog .goods-brand .Select {
  color: rgba(0, 0, 0, 0.9);
  padding: 4px 4px;
  background: #eeeeee;
  border-radius: 4px 4px 4px 4px;
  display: inline;
  font-size: 12px;
}

.Specificationdialog .goods-tab {
  padding: 0 16px;
  margin-bottom: 1.6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Specificationdialog .goods-tab .tabs {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 9px;
}

.Specificationdialog .goods-tab .tabs div:first-child {
  margin-right: 16px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}

.Specificationdialog .goods-tab .all-tabs {
  display: flex;
  align-items: center;
  color: #90d12e;
}

.Specificationdialog .goods-tab .tabs .bottom-bor {
  position: absolute;
  bottom: 0;
  height: 4px;
  background: #90d12e;
  border-radius: 2px 2px 0px 0px;
  transition: ease-out left 0.3s;
}

.Specificationdialog .Specificationselect {
  padding: 0 4.2667vw;
  overflow: scroll;
  height: 100%;
  padding-top: 10px;
  max-height: 270px;
  padding-bottom: 50px;
}

.Specificationdialog .Specificationselect .items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.Specificationdialog .Specificationselect .left {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  padding: 6px 12px;
  border-radius: 4px 4px 4px 4px;
  background: #f8f8f8;
  border: 1px solid transparent;
}

.Specificationdialog .Specificationselect .right {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  width: auto;
}

.Specificationdialog .Specificationselect .right img {
  width: 20px;
}

.Specificationdialog .goodsname {
  margin-top: 12px;
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Specificationdialog .close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.prod-spec {
  padding-top: 0 !important;
}

.SpecificationActive {
  border: 1px solid #90d12e !important;
}

.confirm {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 8px 16px;
  padding-bottom: 16px;

  .confirm-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;

    .confirm-text {
      font-size: 14px;
      color: #1a1a1a;

      span {
        font-size: 14px;
        color: #666666;
        line-height: 22px;
      }
    }

    .confirm-price {
      font-weight: 500;
      font-size: 15px;
      color: #e84935;
    }
  }
}

.confirmbtn {
  width: 49%;
  height: 100%;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  padding: 9px 0;
  color: #fff;
  background-color: #90d12e;
}

.addCart {
  width: 49%;
  height: 100%;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  padding: 9px 0;
  color: #fff;
  background-color: #FFC400;
}

.confirmbtn1 {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  padding: 9px 0;
  color: #fff;
  background-color: #90d12e;
}

.addCart1 {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  padding: 9px 0;
  color: #fff;
  background-color: #FFC400;
}

.buy_all {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 81.0667vw;
  padding: 16px 24px;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  z-index: 3;
}

.buy_all .titles {
  text-align: center;
  margin: 16px 0;
  color: #000000;
  font-weight: 500;
  font-family: PingFang SC, PingFang SC;
}

.buy_all .right {
  flex: 1;
  background: #f8f8f8;
  border-radius: 6px 6px 6px 6px;
  margin: 0 17.6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3.7333vw 3.2vw 4.2667vw;
  margin-bottom: 32px;
}

.buy_all .right img {
  width: 12px;
}

.buy_all .buy_all-cancelbtn {
  flex: 1;
  padding: 9px 0;
  text-align: center;
  border-radius: 22px 22px 22px 22px;
  border: 1px solid #90d12e;
  color: #90d12e;
  margin-right: 12px;
}

.buy_all .buy_all-confirmbtn {
  flex: 1;
  padding: 9px 0;
  text-align: center;
  border-radius: 22px 22px 22px 22px;
  background-color: #90d12e;
  color: #fff;
}

.buy_all-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrive {
  font-weight: 500;
  font-size: 12px;
  color: #90d12e;
  background-color: #fbfbfb;
  padding: 3.8333vw 3.5vw 4.2667vw;
  flex: 1;
  border-radius: 4px;
  margin-left: 8px;
  text-align: center;
  border: 1px solid #f2f3f3;
}

.Sold-out {
  position: absolute;
  right: -4px;
  top: -4px;
  transform: translateY(-40%);
  padding: 2px 4px;
  background-color: #e84935;
  color: #fff;
  border-radius: 2px;
  font-size: 10px;
  line-height: 1;
  white-space: nowrap;
}

.Specificationselect-title {
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;
}

.input-number {
  width: 30px;
  height: 24px;
  text-align: center;
  background: #f8f8f8;
  margin: 0 8px;
  border-radius: 2px 2px 2px 2px;
}

.phone-check {
  position: absolute;
  width: 12px;
  bottom: -1px;
  right: -1px;
}
</style>
