<template>
  <div>
    <div class="download-pannel" v-if="isAppTip && isShow">
      <div class="download-banner">
        <div class="download-close" @click="hideTop">
          <img src="@/assets/image/user/icon-close-banner.png" />
        </div>
        <div class="download-logo">
          <img src="@/assets/image/user/e-Rith.png" />
        </div>
        <div class="download-txt">
          <span class="download-content">
            <em class="content-up" style="font-size: 13px; color: #ffffff">{{ $t("打開App購物") }}</em>
          </span>
        </div>
      </div>
      <div class="download-action" @click="handleOpenApp">
        <span class="font-large" style="color: #ffffff">{{
          $t("立即打開")
        }}</span>
      </div>
    </div>
    <div v-if="isSwipe == 1" class="box">
      <div class="search" @click="handeleSearch">
        <van-swipe class="ipt" vertical :show-indicators="false" :touchable="false" :autoplay="3000"
          indicator-color="white" @change="handleSwipeChange">
          <van-swipe-item v-for="(item,index) in hotlist" :key="index">
            <span class="text">{{ item }}</span>
          </van-swipe-item>
        </van-swipe>
        <div class="search_btn">{{ $t('搜索') }}</div>
      </div>
    </div>
    <div v-else-if="isSwipe == 2" class="box1">
      <div style="display: flex;align-items: center;font-size: 0;">
        <img class="arrow_left" src="@/assets/image/search/Arrow_left@2x.png" @click="handleBack">
        <div class="search">
          <van-field ref="inpRef" class="ipt1" v-model="value" @input="handleInput" placeholder="搜索品牌、名稱" />
          <div class="search_btn" @click="handeleSearchFor">{{ $t('搜索') }}</div>
        </div>
      </div>
      <!-- style="display: none;" -->
      <div class="dropdown" v-if="value">
        <div class="search_text">
          <van-icon name="search" color="#90D12E" size="16" /> <span>在规格型号中搜索“{{ value }}”关键词</span>
        </div>
        <ul class="suggestions">
          <li class="" v-for="(item,index) in suggestionList" :key="index" @click="handleSuggesFor(index)">
            <div v-html="item"></div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="box2">
      <div style="display: flex;align-items: center;font-size: 0;">
        <img class="arrow_left" src="@/assets/image/search/Arrow_left@2x.png" @click="handleBackPage">
        <div class="search" @click="handleFocus">
          <div class="search-val" v-if="value"><span class="text">{{ value }}</span> <van-icon name="cross" color="#999999"
              @click.stop="handleDel()" />
          </div>
          <div v-else></div>
          <div class="search_btn" @click.stop="handeleRefresh">{{ $t('搜索') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyStorage from "@/utils/storage"
import { debounce } from "@/utils/util"
const history_records = new MyStorage("history_records")
import { getSuggestList } from "@/api/search"
export default {
  name: "search",
  props: {
    isSwipe: {
      type: String,
      default: 1
    },
    isShow: {
      type: Boolean,
      default: false
    },
    hotlist: {
      type: Array,
      default: []
    },
  },
  data () {
    return {
      value: "",
      brandID: "",
      CategoriesID: "",
      current: 0,
      isAppTip: true,
      cleanlist: [],
      suggestionList: []
    }
  },
  mounted () {
    this.value = this.$route.query?.name || this.$route.params?.name
    this.brandID = this.$route.query?.brandID || this.$route.params?.brandID
    this.CategoriesID = this.$route.query?.CategoriesID || this.$route.params?.CategoriesID
    this.$emit('isAppTip', this.isAppTip)
    this.getData()
    if (this.isSwipe == 3) {
      if(this.value){
        this.$emit('change', {type:1,str:this.value})
      }
      if(this.brandID){
        this.$emit('change', {type:2,str:this.brandID})
      }
      if(this.CategoriesID){
        this.$emit('change', {type:3,str:this.CategoriesID})
      }
    }
    if (this.isSwipe == 2) {
      this.$nextTick(() => {
        this.$refs.inpRef.focus()
      })
    }
  },
  methods: {
    handleSuggesFor (idx) {
      const keyWord = this.cleanlist[idx].trim()
      if (keyWord) {
        const list = JSON.parse(history_records.get()) || []
        list.unshift(keyWord)
        const uniqueArr = list.filter((item, index) => list.indexOf(item) === index)
        // 最大 30 条，清除多出
        if (uniqueArr.length >= 30) {
          const length = uniqueArr.length - 30
          uniqueArr.splice(30, length)
        }
        history_records.set(JSON.stringify(uniqueArr))
        this.$router.push({
          path: "/productList",
          query: { name: keyWord }
        })
        return
      }
    },
    async handleInput (val) {
      // debounce(this.getData(), 500)
      const keyWord = this.value.trim()
      if (keyWord) {
        const res = await getSuggestList({ id: keyWord })
        this.cleanlist = await res.data
        const arr = await this.cleanlist.map(item => this.keyGoodsName(item, keyWord))
        this.suggestionList = await arr
      } else {
        this.cleanlist = []
        this.suggestionList = []
      }
    },
    /**
* 搜索关键字文字颜色更改
 * @description 可自定义HTML结构
 * @param {String} title - 列表标题
 * @return void
 */
    keyGoodsName (title, searchWord) {
      let s = searchWord // 搜索框的值(您要标红的关键字)
      var str = title // 列表标题(原文本)
      // 去除中间空格且字符之间用逗号隔开
      let inputvalue = s.replace(/\ +/ig, ",") // 把空格分开的字符串转换成以逗号分割
      let keyWordArr2 = inputvalue.split(",") // 把字符串分割转换成数组(方便截取)
      // 判断文本段落(原文本)是否为空
      if (str && str != "") {
        // 遍历分割后的字符串
        keyWordArr2.forEach((e) => {
          let regStr = "" + `(${e})`
          let reg = new RegExp(regStr, "g")
          // 如果匹配成功则抛出关键字DOM
          // TIPS: 这块您可以自定义标签可根据您的需求自定义样式
          str = str.replace(reg, '<span style="color:#90D12E;">' + e + "</span>") // 改变搜索关键字颜色为蓝色
        })
      }
      return str
    },

    async getData () {
      const keyWord = this.value?.trim()
      if (keyWord) {
        const res = await getSuggestList({ id: keyWord })
        this.cleanlist = await res.data
        const arr = await this.cleanlist.map(item => this.keyGoodsName(item, keyWord))
        this.suggestionList = await arr
      } else {
        this.cleanlist = []
        this.suggestionList = []
      }

    },
    handeleRefresh () {
      this.$emit('change', this.value)
    },
    handleFocus () {
      if(this.value){
        this.$router.push(`/searchPage?name=${this.value}`)
      }else{
        this.$router.push(`/searchPage`)
      }
      
    },
    handleDel () {
      this.$router.replace(`/searchPage`)
    },
    handeleSearchFor () {
      const keyWord = this.value.trim()
      if (keyWord) {
        const list = JSON.parse(history_records.get()) || []
        list.unshift(keyWord)
        const uniqueArr = list.filter((item, index) => list.indexOf(item) === index)
        // 最大 30 条，清除多出
        if (uniqueArr.length >= 30) {
          const length = uniqueArr.length - 30
          uniqueArr.splice(30, length)
        }
        history_records.set(JSON.stringify(uniqueArr))
        // this.$router.push(`/productList/${keyWord}`)
        this.$router.push({
          path: "/productList",
          query: { name: keyWord }
        })
        return
      }
    },
    handleSwipeChange (e) {
      this.current = e
    },
    handeleSearch () {
      // const val = this.hotlist[this.current]
      this.$router.push(`/searchPage`)
    },
    handleBackPage(){
      this.$router.back()
    },
    handleBack () {
      this.$router.replace('/home')
    },
    hideTop () {
      this.isAppTip = false
      this.$emit('isAppTip', this.isAppTip)
    },
    handleOpenApp () {
      var u = navigator.userAgent
      var isAndroid = /Android/.test(u) //android终端
      var isiOS = /iPhone|iPad|iPod/.test(u) && /AppleWebKit/.test(u) //ios终端
      console.log(u)
      if (isAndroid) {
        window.open("https://play.google.com/store/apps/details?id=com.rith.shop", '_blank')

      } else if (isiOS) {
        window.location.href =
          "https://apps.apple.com/hk/app/e-rith/id6503116009"
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.box {
  width: 375px;
  margin: 0 auto;
  height: 44px;
  padding: 4px 12px;
  background: linear-gradient(180deg, #F2FFDF 0%, #FFFFFF 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .search {
    width: 100%;
    padding: 6px 4px 6px 0px;
    height: 36px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #90D12E;
    display: flex;
    align-items: center;
    overflow: hidden;

    .ipt {
      width: 290px;
      height: 100%;
      overflow: hidden;

      .text {
        padding-left: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #BDBDBD;
      }
    }

    .search_btn {
      // width: 56px;
      padding: 4px 14px;
      box-sizing: border-box;
      text-align: center;
      font-weight: 400;
      font-size: 13px;
      color: #FFFFFF;
      line-height: 20px;
      background: linear-gradient(98deg, #21F0CB 0%, #91E30B 100%);
      border-radius: 10px 10px 10px 10px;
    }
  }
}

.box1 {
  width: 375px;
  margin: 0 auto;
  height: 44px;
  padding: 4px 12px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #F2FFDF 0%, #FFFFFF 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .search {
    width: 313px;
    padding: 6px 4px 6px 0px;
    height: 36px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #90D12E;
    display: flex;
    align-items: center;
    overflow: hidden;

    .ipt1 {
      width: 250px;
    }

    .search_btn {
      // width: 56px;
      padding: 4px 14px;
      box-sizing: border-box;
      text-align: center;
      font-weight: 400;
      font-size: 13px;
      color: #FFFFFF;
      line-height: 20px;
      background: linear-gradient(98deg, #21F0CB 0%, #91E30B 100%);
      border-radius: 10px 10px 10px 10px;
    }
  }

  .arrow_left {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

.box2 {
  width: 375px;
  margin: 0 auto;
  height: 44px;
  padding: 4px 12px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #F2FFDF 0%, #FFFFFF 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .search {
    width: 313px;
    padding: 6px 4px 6px 4px;
    height: 36px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #b6bea9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    .search-val {

      height: 28px;
      padding: 4px 10px;
      background: #EEEEEE;
      border-radius: 10px 10px 10px 10px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.9);
      display: flex;
      align-items: center;

      .text {
        display: inline-block;
        max-width: 150px;
        white-space: nowrap;
        /* 不换行 */
        overflow: hidden;
        /* 隐藏溢出内容 */
        text-overflow: ellipsis;
      }
    }

    .search_btn {
      padding: 4px 14px;
      box-sizing: border-box;
      text-align: center;
      font-weight: 400;
      font-size: 13px;
      color: #FFFFFF;
      line-height: 20px;
      background: linear-gradient(98deg, #21F0CB 0%, #91E30B 100%);
      border-radius: 10px 10px 10px 10px;
    }
  }

  .arrow_left {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

.download-pannel {
  height: 44px;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url("~@/assets/image/user/topbg.png") 100% 100% no-repeat;
  background-size: cover;

  .download-banner {
    display: flex;
    width: 70%;
    align-items: center;
  }
}

.download-pannel img,
.download-pannel div {
  vertical-align: middle;
}

.download-pannel .pannel-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
}

.download-pannel .pannel-bg img {
  width: 100%;
  height: 100%;
}

.download-pannel .download-close {
  width: 16px;
  margin-left: 2.5%;
}

.download-pannel .download-close img {
  width: 100%;
  height: auto;
}

.download-pannel .download-logo {
  width: 35px;
  margin-left: 5%;
  margin-right: 2.5%;
}

.download-pannel .download-logo img {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

.download-pannel .download-txt {
  height: 100%;
}

.download-pannel .download-content {
  font-size: 12px;
  display: block;
  position: relative;
}

.download-pannel .download-content .content-up {
  color: #fff;
  font-weight: 500;
}

.download-pannel em {
  font-style: normal;
}

.download-pannel .download-content {
  font-size: 12px;
  display: block;
  position: relative;
}

.download-pannel .download-action {
  text-align: center;
  font-size: 13px;
  color: #fff;
  flex: 1;
}

.download-pannel .font-large {
  font-size: 15px;
}

// .download-pannel div[class^="download-"] {
//   position: relative;
//   z-index: 5;
//   float: left;
//   overflow: hidden;
// }

.download-pannel .download-content .content-down {
  color: #999;
  position: absolute;
  top: 9px;
}

.dropdown {
  margin: 0 auto;
  width: 375px;
  height: 100%;
  padding: 10px 19px 10px 16px;
  background: #FFFFFF;
  box-sizing: border-box;
  position: fixed;
  left: 50%;
  top: 46px;
  transform: translate(-50%);
  z-index: 1;
}

.search_text {
  width: 100%;
  padding: 12px 0;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 13px;
  color: #90D12E;
  line-height: 20px;
  text-align: left;
}

.suggestions {
  width: 100%;
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  margin: 0;
}

.suggestions li {
  padding: 12px 0;
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  /* 不换行 */
  overflow: hidden;
  /* 隐藏溢出内容 */
  text-overflow: ellipsis;
  cursor: pointer;
}

// .suggestions li:hover {
//   background-color: #eee;
// }</style>