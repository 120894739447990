<template>
  <div>
    <div v-if="activeData.template && activeData.template.backgroundColor" class="wrapper618" :style="{
      'padding-bottom': '90px',
      background: activeData.template.backgroundColor
    }">
      <div ref="tab-top" :class="{ 'no-header': isfixTab }">
        <div v-if="activeData.template.mainPicture">
          <div>
            <img :src="`${imgUrl}${activeData.template.mainPicture}`" width="100%" @error="handleImageError" />
          </div>
        </div>
      </div>
      <!-- tab栏 -->
      <template v-if="
        activeData &&
        activeData.positioningTagList &&
        activeData.positioningTagList.length > 1
      ">
        <div :class="{ 'fix-tab': isfixTab }">
          <div class="hidden-scroll-x">
            <div class="tab-list" ref="tabs" @click="switchTab">
              <div style="position: relative;" :class="['tab-item',{ active: index === activeTab }]"
                v-for="(tab,index) in activeData.positioningTagList" :key="tab.bannerId" :data-index="index">
                <span class="tab-name"> </span>
                <span class="tabbannerName" :data-index="index">{{
                  langEnv == 3
                    ? tab.bannerName
                    : tab.englishBannerName
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- 优惠券 -->
      <!-- <div style="display: flex; flex-wrap: wrap" class="hongbaowrapper">
        <div
          titleUrl
          v-for="item in activeData.couponList"
          :key="item.id"
          :style="
            activeData.template.couponEmissionType == 6 ||
            activeData.template.couponEmissionType == 3
              ? 'width:33.3333%'
              : activeData.template.couponEmissionType == 4 ||
                activeData.template.couponEmissionType == 2
              ? 'width:50%'
              : 'width:100%'
          "
        >
          <img
            @click="goTo(item.couponId)"
            :src="`${imgUrl}${item.couponUrl}`"
          />
        </div>
      </div> -->

      <div v-if="activeData.many">
        <!-- 先遍历最外层数组 -->
        <div v-for="item in activeData.many.manyAreaJson" :key="item.modelSort">
          <div>
            <!-- modelType来判断是商品还是专区列表 -->
            <div v-if="item.modelType == 0">
              <div v-if="item.specialAreaBanner && item.englishSpecialAreaBanner">
                <div>
                  <!-- 专区的头部bannner -->
                  <img :src="`${imgUrl}${langEnv == 3
                    ? item.specialAreaBanner
                    : item.englishSpecialAreaBanner
                    }`
                    " @error="handleImageError" width="100%" />
                </div>
              </div>
              <div style="display: flex; flex-wrap: wrap" class="hongbaowrapper">
                <!-- 专区只存在图片  -->
                <div v-for="(s,i) in item.specialList" :key="i" :style="item.specialAreaEmissionType == 6 ||
                  item.specialAreaEmissionType == 3
                  ? 'width:33.3333%'
                  : item.specialAreaEmissionType == 4 ||
                    item.specialAreaEmissionType == 2
                    ? 'width:50%'
                    : 'width:100%'
                  ">
                  <img @click="goToLink(s.link)" :src="`${imgUrl}${langEnv == 3
                    ? s.specialAreaUrl
                    : s.englishSpecialAreaUrl
                    }`
                    " @error="handleImageError" />
                </div>
              </div>
            </div>

            <div v-if="item.modelType == 1">
              <div v-for="(m,l) in item.manyProduct" :key="l" :ref="`scrollCont${item.productModelSoft - 1}`">
                <div>
                  <div v-if="m.titleUrl">
                    <img :src="`${imgUrl}${langEnv == 3
                      ? m.titleUrl
                      : m.englishTitleUrl
                      }`
                      " width="100%" @error="handleImageError" />
                  </div>
                  <div :class="m.arrangementType == 2 ? 'listTwo' : 'listOne'" style="display: flex;flex-wrap: wrap">
                    <div style="object-fit: contain;" class="listBox" v-for="(item1,i1) in m.productList"
                      @click="goDetail(item1.searchProductId)" :key="i1">
                      <img :src="imgUrl + item1.picturePath" @error="handleImageError" />

                      <div class="bottomBox">
                        <div class="name">{{ item1.goodsName }}</div>
                        <!-- <div class="tags">
                          <div class="tag-item">
                            限时折扣
                          </div>
                        </div> -->
                        <div class="btnBox">
                          <div class="pricebox" v-if="item1.activityPrice">
                            <div>
                              <div class="activityPrice" v-if="item1.activityPrice === 1000000">{{ $t("詢價") }}</div>
                              <div v-else class="activityPrice">{{ formatNumber(item1.activityPrice) }}</div>
                              <div class="price"
                                v-if="item1.activityPrice !== 1000000 && item1.activityPrice !== item1.sellingPrice">
                                {{ formatNumber(item1.sellingPrice) }}
                              </div>
                            </div>
                            <div class="add" @click.stop="handleBuyProduct(item1)">
                              <img src="@/assets/image/search/add.png">
                            </div>
                          </div>

                          <!-- <div
                            class="buyNow"
                            @click="goBuyNow(item1.searchProductId, $event)"
                            :style="{ background: m.buttonColor }"
                            v-if="m.buttonType == '2'"
                          >
                            {{ m.buttonFont }}
                          </div> -->
                          <!-- <div
                            class="cartshoping"
                            @click="goBuyNow(item1.searchProductId, $event)"
                          >
                            <img
                              v-if="m.arrangementType == 2"
                              src="@/assets/image/active/shoping.png"
                            />
                            <img
                              v-else
                              src="@/assets/image/active/buymore.png"
                            />
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div v-if="activeData.template.specialAreaBanner">
          <div>
            <img :src="`${imgUrl}${activeData.template.specialAreaBanner}`" width="100%" @error="handleImageError" />
          </div>
        </div>
        <div style="display: flex; flex-wrap: wrap" class="hongbaowrapper">
          <div v-for="item in activeData.specialAreaList" :key="item.id" :style="activeData.template.specialAreaEmissionType == 6 ||
            activeData.template.specialAreaEmissionType == 3
            ? 'width:33.3333%'
            : activeData.template.specialAreaEmissionType == 4 ||
              activeData.template.specialAreaEmissionType == 2
              ? 'width:50%'
              : 'width:100%'
            ">
            <img @click="goToLink(item.link)" :src="`${imgUrl}${langEnv == 3
              ? item.specialAreaUrl
              : item.englishSpecialAreaUrl
              }`
              " @error="handleImageError" />
          </div>
        </div>

        <!-- <template v-if="activeData && activeData.positioningTagList && activeData.positioningTagList.length > 1">
        <div :class="{ 'fix-tab': isfixTab }">
          <div class='hidden-scroll-x'>
            <div
              class="tab-list"
              ref="tabs"
              @click="switchTab"
            >
              <div
                :class="['tab-item', { active: index === activeTab }]"
                v-for="(tab, index) in activeData.positioningTagList"
                :key="tab.bannerId"
                :data-index="index"
              >
                <span :data-index="index">{{ tab.bannerName }}</span>
              </div>
            </div>
          </div>
        </div>
      </template> -->
        <!--手套-->
        <div v-for="(item,index) in activeData.productAreaList" :key="item.id" :ref="`scrollCont${index}`">
          <div>
            <div v-if="item.titleUrl">
              <img :src="`${imgUrl}${langEnv == 3
                ? item.titleUrl
                : item.englishTitleUrl
                }`
                " width="100%" @error="handleImageError" />
            </div>
            <div :class="item.arrangementType == 2 ? 'listTwo' : 'listOne'" style="display: flex;flex-wrap: wrap">
              <div class="listBox" v-for="item1 in item.productList" @click="goDetail(item1.searchProductId)"
                :key="item1.id">
                <img :src="`${imgUrl1}${item1.picturePath}`" @error="handleImageError" />
                <div class="bottomBox">
                  <div class="name">{{ item1.goodsName }}</div>
                  <div class="price" v-if="
                    item1.sellingPrice &&
                    item1.sellingPrice != item1.activityPrice
                  ">
                    {{ formatNumber(item1.sellingPrice) }} > {{
                      formatNumber(item1.activityPrice)
                    }}
                  </div>
                  <div class="buyNow" @click="goBuyNow(item1.searchProductId,$event)"
                    :style="{ background: item.buttonColor }" v-if="item.buttonType == '2'">
                    {{ item.buttonFont }}
                  </div>
                  <div class="buyNow buyNow1" @click="goBuyNow(item1.searchProductId,$event)"
                    :style="{ background: item.buttonColor }" v-if="item.buttonType == '1'">
                    <img src="@/assets/image/active/shoping.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div>
        <div><img src="@/assets/image/active/header.png" alt="" /></div>
        <div class="advertisement-banner">
          <img src="@/assets/image/active/banner.png" alt="" />
        </div>
        <div class="advertisement-list">
          <img src="@/assets/image/active/advertisement.png" alt="" />
          <img src="@/assets/image/active/advertisement.png" alt="" />
          <img src="@/assets/image/active/advertisement.png" alt="" />
        </div>
        <div class="Max-out">
          <div class="item">
          <div class="top">
            <div class="price">¥6</div>
            <div class="Max-price">
              <div class="Max-text">满100可用</div>
              <div>仅牙体牙髓商品可用</div>
            </div>
          </div>

          </div>
          <img src="@/assets/image/active/favorable.png" alt="" />
          <img src="@/assets/image/active/favorable.png" alt="" />
        </div>
      </div> -->
    </div>
    <specificationDialog v-if="isSpecificationDialog1" @handleConfirm="handleConfirm" :isAuth="isAuth"
      @handleClose="handlespecificationClose" :show="isSpecificationDialog" :tagtype="submitGoodsList.tagtype"
      :searchProductId="detailId"></specificationDialog>
  </div>
</template>
<script>
import Mixins from "@/utils/mixins"
// import ComHeader from "../../components/ComHeader.vue";
import { getProduct, getProductcreateTempOrder } from "@/api/productdetails.js"
import Config from "@/utils/config"
import { getSearchSeeDetails } from "@/api/active"
import { debounce } from "@/utils/util"
export default {
  mixins: [Mixins],
  // components: { ComHeader },
  data: () => ({
    imgUrl: Config.imgUrl,
    backUrl: "/",
    title: "",
    activeData: {},
    activeTab: 0,
    isfixTab: false,
    contRefs: [],
    langEnv: null,
    scrollEventLock: false,// 滚动事件触发锁
    defaultImg: require('@/assets/image/e-Rith.png'),
    detailId: null,
    submitGoodsList: {
      goods: [],
      isFromShoppingCart: false,
      isPreOrder: false,
    },
    isSpecificationDialog1: false,
    isSpecificationDialog: false,
    isAuth: {}
  }),
  watch: {
    activeData: {
      handler () {
        this.initScroContRefs()
      },
      deep: true
    },
    activeTab (val, oldVal) {
      if (oldVal === val) {
        return
      }
      const isLeft = oldVal - val > 0 ? true : false // 判断点击当前激活的左侧tab（正数）还是右侧tab（负数）
      if (!this.activeData || !this.activeData.positioningTagList.length) {
        return
      }
      const tabEl = this.$refs.tabs
      const tabItemEl = tabEl.querySelectorAll(".tab-item")
      // 切换tab时，第一、二个tab和倒数一、二个tab固定，直接移动到最左侧或者最右侧
      if (val === 0 || val === 1) {
        tabEl.scrollTo({
          left: 0,
          behavior: "smooth"
        })
      } else if (val === tabItemEl.length - 1 || val === tabItemEl.length - 2) {
        let l = 0
        tabItemEl.forEach(el => {
          l += el.offsetWidth
        })
        tabEl.scrollTo({
          left: l,
          behavior: "smooth"
        })
      } else {
        // 切换的非上面两种tab，需要计算移动的距离，为：前/后 第二个tab的宽度的一半 + margin间距
        let l = tabEl.scrollLeft
        if (isLeft) {
          const i = oldVal - 2 > 0 ? oldVal - 2 : oldVal
          l -= tabItemEl[i].offsetWidth / 2 + 18
        } else {
          const i = oldVal + 2 > tabItemEl.length - 1 ? oldVal + 2 : oldVal
          l += tabItemEl[i].offsetWidth / 2 + 18
        }
        tabEl.scrollTo({
          left: l,
          behavior: "smooth"
        })
      }
    }
  },
  methods: {
    // 立即購買
    async handleBuyProduct (row) {
      this.detailId = row.searchProductId
      const res = await getProduct({ id: row.searchProductId })
      if (res.resultID == 1200) {
        this.list = res.data
        if (this.list.page == null) {
          this.list.page = ""
        }
        this.list.page = this.appendDomainToImgSrc(
          this.list.page,
          Config.imgUrl.replace("/upload", "")
        )
        this.list.page = this.replaceLinks(
          this.list.page,
          Config.imgUrl.replace("/upload", "")
        )
        this.isAuth = { isAuth: this.list.isAuth, text: this.list.text,btnType:2 }

        this.isSpecificationDialog = true
      }

    },
    // 下单
    async handleConfirm (data) {
      var _this = this
      this.submitGoodsList.goods = data.map(item => {
        return {
          searchProductId: item.searchProductId,
          id: item.searchProductId,
          count: item.amount,
          tagType: ''
        }
      })
      var func1 = function () {
        mmm.placeOrderList(JSON.stringify(_this.submitGoodsList.goods))
      }
      var func2 = function () {
        window.webkit.messageHandlers.placeOrderList.postMessage({ goods: JSON.stringify(_this.submitGoodsList.goods) })
      }
      var func3 = async function () {
        const res = await getProductcreateTempOrder(_this.submitGoodsList)
        if (res.resultID === 1200) {
          _this.$router.push("/order/confirm2")
        }
      }

      this.excFunc(func1, func2, func3)

    },
    appendDomainToImgSrc (htmlString, domain) {
      // 正则表达式匹配<img>标签的src属性
      const imgSrcPattern = /<img\s+[^>]*src\s*=\s*["']([^"']+)["'][^>]*>/g

      // 回调函数，用于替换匹配到的src值
      function replaceSrc (match, src) {
        // 检查src是否以http或https开头
        if (src.startsWith('http://') || src.startsWith('https://')) {
          return match // 如果是，则返回原始字符串
        }

        // 拼接域名和原src路径
        const newSrc = domain + src
        // 重新构造<img>标签，确保其他属性不变
        return match.replace(src, newSrc)
      }

      // 使用正则表达式替换所有匹配到的src值
      const resultHtml = htmlString?.replace(imgSrcPattern, replaceSrc)

      return resultHtml
    },
    replaceLinks (text, domain) {
      // 正则表达式匹配 href 属性中的路径
      const regex = /href="(\/upload\/files\/file[^"]*)"/g
      // 替换函数
      return text.replace(regex, (match, path) => {
        // 在路径前添加域名
        return match.replace(path, `${domain}${path}`)
      })
    },
    handlespecificationClose () {
      this.isSpecificationDialog = false
    },
    async getList (langEnv) {
      const res = await getSearchSeeDetails({
        id: this.$route.params.id,
        langEnv:
          langEnv == 3 ? "TRADITIONAL_CHINESE" : "ENGLISH"
      })
      this.activeData = res.data
    },
    isLogin () {
      var token = this.$cookie.get("token")
      if (!token) {
        var _this = this
        var func1 = function () {
          mmm.toLogin()
        }
        var func2 = function () {
          window.webkit.messageHandlers.toLogin.postMessage("jj")
        }
        var func3 = function () {
          return
          // _this.$router.push({
          //   path: "/login?returnUrl=" + encodeURIComponent("/getCoupons")
          // });
        }
        this.excFunc(func1, func2, func3)
      } else {
        return true
      }
    },
    goTo (id) {
      if (this.isLogin()) {
        // this.$store.dispatch("getCous3", id);
      }
    },
    goBack () {
      this.$router.push({
        path: "/"
      })
    },
    goToLink (link) {
      if (!link) {
        return
      }
      this.$router.push({
        path: link
      })
    },
    goDetail (id) {
      var _this = this
      var func1 = function () {
        mmm.goToDetail(id)
      }
      var func2 = function () {
        window.webkit.messageHandlers.goToDetail.postMessage(id)
      }
      var func3 = function () {
        _this.$router.push(`/product/${id}`)
      }

      this.excFunc1(func1, func2, func3)
    },
    goBuyNow (idNo, e) {
      e.stopImmediatePropagation()
      e.stopPropagation()
      var _this = this
      var func1 = function () {
        mmm.getAllStandard(idNo)
      }
      var func2 = function () {
        window.webkit.messageHandlers.getAllStandard.postMessage({ id: idNo })
      }
      var func3 = function () {
        return
        // _this.$store.dispatch("getAllStandard", { id: idNo });
      }
      this.excFunc(func1, func2, func3)
    },
    switchTab (e) {
      // 当前点击的 tab 下标
      const index = e.target.dataset.index
      // 45 是 tab-list 的高度
      const scrollTop = this.contRefs[index].offsetTop - 42
      if (this.isWeiXin()) {
        window.scrollTop = scrollTop
      }
      document.documentElement.scrollTop = scrollTop
      document.body.scrollTop = scrollTop
      this.activeTab = +index
      // 滚动事件上锁
      this.scrollEventLock = true
    },
    debounceHandleScrollView: debounce("handleScrollView", 20),
    handleTab () {
      if (!this.hasTabList) {
        window.removeEventListener("scroll", this.handleTab)
        return
      }
      let scrollTop =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset
      // 即tab-list距离顶部的内容
      let offsetHeight = this.$refs["tab-top"].offsetHeight

      this.isfixTab = scrollTop >= offsetHeight ? true : false
      // 已上锁 则 不需要再次判断激活的 tab，否则 通过元素与页面顶部的距离进行判断
      if (!this.scrollEventLock) {
        this.debounceHandleScrollView()
      } else {
        this.scrollEventLock = false
      }
    },
    // 滚动时 激活对应 tab。内容区距离顶部距离是否小于等于 tab-list 高度，从下往上的顺序进行判断
    handleScrollView () {
      // 获取到 tab-list 高度
      const tabH = this.$refs.tabs?.offsetHeight
      const len = this.contRefs.length
      for (let i = len - 1; i >= 0; i--) {
        const contRef = this.contRefs[i]
        const listBoxH = contRef.getElementsByClassName("listBox")[0]
          .offsetHeight

        if (contRef.getBoundingClientRect().top <= tabH + listBoxH * 0.7) {
          this.activeTab = i
          break
        }
      }
    },
    // 监听页面滚动事件
    initScroll () {
      window.addEventListener("scroll", this.handleTab)
    },
    initScroContRefs () {
      const len = this.activeData?.positioningTagList?.length

      this.$nextTick(() => {
        // 获取每个 tab 的内容区实例，按照 tab 的顺序存
        for (let i = 0; i < len; i++) {
          this.contRefs.push(this.$refs[`scrollCont${i}`][0])
        }
      })
    },
    handleImageError (e) {
      e.target.src = this.defaultImg // 只需要将此路径换成对应的默认图片路径即可
    },
  },
  beforeRouteUpdate: function (to, from, next) {
    next()
    // this.$store.dispatch("getActive", { id: this.$route.params.id });
  },
  created () {
    // this.$store.dispatch("getActive", { id: this.$route.params.id });
    this.langEnv = this.$route.query.langEnv || window.localStorage.getItem("langEnv")
    this.getList(this.langEnv)
  },
  mounted () {
    this.initScroll()
    this.isSpecificationDialog1 = true
  },
  beforeUnmount () {
    this.isSpecificationDialog1 = false
  },
  updated () {
    if (!this.activeData || !this.activeData.template) {
      return
    }
    document.title = this.activeData.template.activityTitle
    let that = this
    // Request.fetchPost(
    //   "config",
    //   { id: encodeURIComponent(`${window.location.href}`) },
    //   null,
    //   "POST",
    //   false,
    //   true
    // ).then(res => {
    //   let config = res.data;
    //   wx.config({
    //     debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //     appId: config.appId, // 必填，公众号的唯一标识
    //     timestamp: config.timestamp, // 必填，生成签名的时间戳
    //     nonceStr: config.nonceStr, // 必填，生成签名的随机串
    //     signature: config.signature, // 必填，签名
    //     jsApiList: [
    //       "updateAppMessageShareData",
    //       "updateTimelineShareData",
    //       "onMenuShareAppMessage",
    //       "onMenuShareTimeline",
    //       "onMenuShareQQ",
    //       "onMenuShareQZone"
    //     ]
    //   });
    //   var shareData = {
    //     title:
    //       that.activeData && that.activeData.template
    //         ? that.activeData.template.activityTitle
    //         : "买药品和耗材，就上梅苗苗商城", // 分享标题
    //     desc: "买药品和耗材，就上梅苗苗", // 分享描述
    //     link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //     imgUrl: `${that.imgUrl}${that.activeData.template.mainPicture}`, // 分享图标
    //     success: function() {
    //       // that.showMsg("分享成功");
    //     },
    //     fail: function(s) {
    //       //alert(JSON.stringify(s))
    //       // that.showMsg("分享成功");
    //     }
    //   };
    //   wx.ready(function() {
    //     wx.updateAppMessageShareData(shareData);
    //   });
    //   wx.ready(function() {
    //     wx.updateTimelineShareData(shareData);
    //   });
    // });
  },
  // 离开时清除滚动监听
  beforeRouteLeave (to, from, next) {
    window.removeEventListener("scroll", this.handleTab)
    next()
  },
  computed: {
    // ...mapGetters(["activeData"]),
    hasTabList () {
      return (
        this.activeData &&
        this.activeData.positioningTagList &&
        this.activeData.positioningTagList.length > 0
      )
    }
  }
}
</script>
<style scoped lang="scss">
.listTwo .listBox {
  margin: 1%;
  width: 48%;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
}

.listTwo {
  justify-content: space-between;
}

.listTwo .listBox .name {
  height: 34px;
  line-height: 17px;
  white-space: normal;
  margin-top: 5px;
  font-size: 12px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.listOne .listBox .name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.listTwo .listBox .btnBox {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.listTwo,
.listOne {
  padding: 0 6px;
}

.listTwo .listBox .bottomBox {
  padding: 0 6px 6px;
}

.listOne .listBox {
  display: flex;
  background: #fff;
  margin: 1.5%;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
}

.listOne .listBox .bottomBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 10px;
  flex: 1;
}

.listTwo .listBox .price,
.listOne .listBox .price {
  color: #b3b3b3;
  font-size: 12px;
  padding: 4px 0;
  text-decoration: line-through;
  height: 26px;
  box-sizing: border-box;
  font-weight: normal;
  white-space: nowrap;
}

.listOne .listBox {
  display: flex;
}

.listOne .listBox img {
  width: 156px;
  object-fit: contain;
}

.listOne .listBox .btnBox {
  display: flex;
  justify-content: space-between;
}

.buyNow {
  font-size: 12px;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  border-radius: 5px;
  color: #fff;
  height: 24px;
  padding: 0 10px;
  white-space: nowrap;
}

.hongbaowrapper {
  display: flex;
  background: #ea2900;
  justify-content: space-around;
}

.hongbaowrapper img {
  width: 100% !important;
}

.font_img {
  width: 90% !important;
  margin-top: 5px;
}

.tejiayp,
.tejiayp1 {
  display: flex;
  flex-direction: column;
}

.pro2Top,
.pro2Name {
  font-size: 12px;
  -webkit-line-clamp: 1;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pro2Top {
  white-space: nowrap;
  font-size: 14px;
  height: 20px;
}

.pro2Name {
  box-sizing: border-box;
  height: 31px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  color: #fff;
  margin-top: 5px;
  line-height: 16px;
  margin-bottom: 3px;
}

.tejiayp,
.tejiayp1 {
  width: 50%;
  color: #fff;
  position: relative;
}

.tejiayp .titleName {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ff0036;
  padding: 10px;
  font-size: 18px;
  box-sizing: border-box;
  line-height: 24px;
  text-align: center;
  display: flex;
  -webkit-line-clamp: 3;
  flex-direction: column;
  height: 70px;
  overflow: hidden;
  border-radius: 12px 10px 0 0;
}

.tejiayp .titleName div.namea {
  -webkit-line-clamp: 1;
  flex-direction: column;
  overflow: hidden;
}

.tejiayp .pic {
  width: 100%;
  overflow: hidden;
}

.tejiayp .price {
  background: #ff0036;
  line-height: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: -webkit-linear-gradient(#fa0507, #d61628);
  margin-top: 6px;
}

.wrapper618 {
  background: #fff;
}

.wrapper618 img {
  width: 100%;
}

.couponWrapper {
  padding-top: 20px;
}

.wrapper618 .couponWrapper {
  overflow: hidden;
}

.couponWrapper .leftImg {
  float: left;
  width: 44%;
  margin-left: 4%;
}

.couponWrapper .rightImg {
  float: right;
  width: 44%;
  margin-right: 4%;
}

.deng {
  position: relative;
}

.deng .quan {
  position: absolute;
  bottom: 35.555555%;
  left: 7%;
  width: 25%;
  text-align: center;
  color: transparent;
  height: 41%;
}

.deng .quan2 {
  left: 36.55555%;
}

.deng .quan3 {
  left: 66%;
}

.btnNow1,
.btnNow2,
.btnNow3 {
  position: absolute;
  bottom: 9%;
  right: 11%;
  width: 23%;
  text-align: center;
  color: transparent;
}

.buyNow1 {
  border-radius: 40px;
}

.buyNow1 img {
  width: 20px;
  height: 20px;
}

.fix-tab {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.hidden-scroll-x {
  position: relative;
  width: 100%;
  /* overflow: hidden; */
  /* height: 43px; */
  border-bottom: 1px solid #eee;
}

.tab-list {
  // display: flex;
  /* height: 53px; */
  overflow: auto;
  align-items: center;
  /* overflow-y: hidden; */
  white-space: nowrap;
  background: #fff;
  font-size: 12px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  padding: 0 10px;
}

.tab-list::-webkit-scrollbar {
  width: 0 !important;
}

.tab-list .tab-item {
  margin: 0 16px;
  display: inline-block;
  text-align: center;
  padding: 16px 0;
  color: #8e8e8e;
  font-size: 15px;
  vertical-align: middle;
}

.tab-list .tab-item.active .tab-name {
  position: absolute;
  background: #333;
  width: 20px;
  height: 3px;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
}

.tab-list .tab-item.active {
  color: #333333;
  position: relative;
  font-weight: bold;
}

.no-header {
  margin-top: 45px;
}

::-webkit-scrollbar {
  display: none;
}

/* 去掉ios横向滚动条 */
*::-webkit-scrollbar:horizontal {
  width: 0;
  height: 0;
  display: none;
}

.activityPrice {
  font-family: PingFang SC, PingFang SC;
  font-weight: 800;
  font-size: 16px;
  color: #f82207;
  margin: 5px 0;
}

.cartshoping {
  width: 32px;
  height: 32px;

  img {
    width: 100% !important;
  }
}

.tags {
  display: flex;

  .tag-item {
    padding: 1px 4px;
    background: #f82207;
    border-radius: 2px 2px 2px 2px;
    font-size: 11px;
    color: #fff;
    margin-top: 8px;
  }
}

.advertisement-list {
  display: flex;
  width: 100%;
  padding: 0 5px;
  justify-content: space-between;

  img {
    width: 33%;
    object-fit: contain;
  }
}

.Max-out {
  display: flex;

  img {
    width: 50%;
  }

  .item {
    flex: 1;
    height: 120px;
    background: #ffffff;
    border-radius: 12px;
    padding: 20px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .Max-price {
        font-size: 10px;
        color: #000000;

        .Max-text {
          font-size: 17px;
          font-weight: 800;
        }
      }
    }
  }
}

.advertisement-banner {
  padding: 10px;
  border-radius: 20px;

  img {
    border-radius: 20px;
  }
}

.tabbannerName {
  max-width: 92px;
  white-space: normal;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-height: 1;
}

.pricebox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 700;
  color: red;

  .add {
    width: 24px;
    height: 24px;
    font-size: 0;
    margin: 5px 0;

    img {
      width: 100%;
      height: 100%
    }
  }
}
</style>
