<template>
  <div
    v-if="!isMobileTerminal()"
    :class="['header-container', { isFixed: isFixed }]"
    :style="bgc || 'background: transparent;'"
  >
    <div class="left">
      <img @click="gotoBack" src="@/assets/icons/Arrow_left.png" alt="" />
      <div class="title">{{ title ? $t(`${title}`) : "" }}</div>
    </div>
    <slot name="right"></slot>
  </div>
</template>

<script>
import mixins from "@/utils/mixins.js";
export default {
  name: "navBar",
  mixins: [mixins],
  props: {
    title: {
      type: String,
      default: ""
    },
    goBack: {
      type: Function
    },
    bgc: {
      type: String,
      default: ""
    },
    isFixed: {
      type: Boolean,
      default: false
    },
    isNavWx: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    gotoBack() {
      if (this.goBack) {
        this.goBack();
      } else {
        this.$router.back();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.isFixed {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  z-index: 1;
}
.header-container {
  height: 44px;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
  }
  .title {
    margin-right: 4px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 28px;
  }
  img {
    width: 24px;
  }
}
</style>
