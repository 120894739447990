import baseURL from "@/utils/config";
import request from "@/plugins/axios";
export function getUser(data, langEnv = 2) {
  return request({
    url: `${baseURL.api}/authapi/user/userinfo2`,
    method: "post",
    data
  });
}
//地址
export function getAddress(data, langEnv = 2) {
  return request({
    url: `${baseURL.api}/api/getAddress`,
    method: "post",
    data
  });
}
//邮箱校验
export function getvalidateEmail(data, langEnv = 2) {
  return request({
    url: `${baseURL.api}/api/validateEmail`,
    method: "post",
    data
  });
}
export function validatePhone(data, langEnv = 2) {
  return request({
    url: `${baseURL.api}/api/validatePhone`,
    method: "post",
    data
  });
}
// 发送短信验证码
export function getCaptchaNew(data) {
  return request({
    url: baseURL.api + "/api/getCaptchaNew",
    method: "post",
    data
  });
}
//注册提交
export function registerNewLogin(data) {
  return request({
    url: baseURL.api + "/api/registerNewLogin",
    method: "post",
    data
  });
}
//提交认证
export function getRegisterNew(data) {
  return request({
    url: baseURL.api + "/api/registerNew",
    method: "post",
    data
  });
}
// 校验验证码
export function validatePhoneCaptcha(data) {
  return request({
    url: baseURL.api + "/api/validatePhoneCaptcha",
    method: "post",
    data
  });
}
// 登录
export function loginByCaptcha(data) {
  return request({
    url: baseURL.api + "/api/login-by-captcha",
    method: "post",
    data,
  });
}
// 重置密码
export function forgetPwdUpdatePwd(data) {
  return request({
    url: baseURL.api + "/api/forgetPwd_UpdatePwd",
    method: "post",
    data
  });
}
//获取地址列表
export function getAddressList() {
  return request({
    url: baseURL.api + "/authapi/user/searchDeliveryAddress",
    method: "post"
  });
}
//修改默认地址
export function getEditDefaultAddress(data) {
  return request({
    url: baseURL.api + "/authapi/user/defaultDeliveryAddress",
    method: "post",
    data
  });
}
//修改配送地址
export function getEditDistributionAddress(data) {
  return request({
    url: baseURL.api + "/authapi/order/changeAddr",
    method: "post",
    data
  });
}
//新增收货地址
export function getAddedShippingAddress(data) {
  return request({
    url: baseURL.api + "/authapi/user/addDeliveryAddress",
    method: "post",
    data
  });
}
//删除收货地址
export function getDelShippingAddress(data) {
  return request({
    url: baseURL.api + "/authapi/user/deleteDeliveryAddress",
    method: "post",
    data
  });
}
//地区手机编码
export function getPhoneAreaCode(data) {
  return request({
    url: baseURL.api + "/api/getAreaCode",
    method: "post",
    data
  });
}
// /authapi/user/updateDeliveryAddress
export function getEditPhoneAreaCode(data) {
  return request({
    url: baseURL.api + "/authapi/user/updateDeliveryAddress",
    method: "post",
    data
  });
}
// /authapi/order/createOrder2
export function getCreateOrder2(data) {
  return request({
    url: baseURL.api + "/authapi/order/createOrder2",
    method: "post",
    data
  });
}
// 轮询订单状态
export function pollOrderStatus(data) {
  return request({
    url: baseURL.api + "/authapi/order/pollOrderStatus",
    method: "post",
    data
  });
}

// 获取支付订单
export function getPayOrder(data) {
  return request({
    url: baseURL.api + "/authapi/pay/getPayOrder",
    method: "post",
    data
  });
}
// 获取订单详情
export function getOrderDetails(data) {
  return request({
    url: baseURL.api + "/authapi/order/getOrderDetails",
    method: "post",
    data
  });
}
// 获取订单详情
export function getSubmitPayOrder2(data) {
  return request({
    url: baseURL.api + "/authapi/pay/payOrder2",
    method: "post",
    data
  });
}
// 轮询支付结果
export function pollOrderStatusByPay(data) {
  return request({
    url: baseURL.api + "/authapi/order/pollOrderStatusByPay",
    method: "post",
    data
  });
}
//Stripe支付获取key
export function getStripeKey() {
  return request({
    url: baseURL.api + "/authapi/pay/getStripeKey",
    method: "post"
  });
}
// 关闭订单
export function closeOrder(data) {
  return request({
    url: baseURL.api + "/authapi/order/closeOrder",
    method: "post",
    data
  });
}
//获取订单列表
export function getOrderList(data) {
  return request({
    url: baseURL.api + "/authapi/order/getOrders2",
    method: "post",
    data
  });
} 
//获取订单列表
export function getConfig(data) {
  return request({
    url: baseURL.api + "/api/config",
    method: "post",
    data
  });
}
//获取openid
export function getOpenId(data) {
  return request({
    url: baseURL.api + "/api/wxOauthCallback",
    method: "post",
    data
  });
}
//获取wxOauth
export function getWxOauth(data) {
  return request({
    url: baseURL.api + "/api/wxOauth",
    method: "post",
    data
  });
}
//获取openidcourse/pay
// export function getWxpayOrderByKey(data) {
//   return request({
//     url: baseURL.api + "/api/payOrderByKey",
//     method: "post",
//     data
//   });
// }

//获取邀请码
export function getMyQrCode(data) {
  return request({
    url: baseURL.api + "/authapi/user/myQrCode",
    method: "post",
    data
  });
}
//获取邀请码海报
export function getMyQrCodePlacard(data) {
  return request({
    url: baseURL.api + "/authapi/user/myCode",
    method: "post",
    data
  });
}
//获取已邀请列表
export function getMyInviteList(data) {
  return request({
    url: baseURL.api + "/authapi/user/searchInvitationUser",
    method: "post",
    data
  });
}