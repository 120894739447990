<template>
  <div class="page">
    <search :isSwipe="2" />
    <!-- 历史搜索 -->
    <div class="search-box" v-if="historyList?.length">
      <div class="box-header">
        <div class="title">{{ $t('历史搜索') }}</div>
        <img v-if="!isDel" @click="handleisDel" class="edit-img" src="@/assets/image/search/edit@2x.png">
        <div v-else class="box-header-right">
          <div class="allDel" @click="handleAlldel">{{ $t('全部删除') }}</div>
          <div class="line"></div>
          <div class="finish" @click="handleFinish">{{ $t('完成') }}</div>
        </div>
      </div>
      <div class="box-main" ref="boxMain">
        <div ref="childElements" class="history-item" @click.stop="handeleSearchFor(item)"
          v-for="(item,idx) in historyList" :key="idx">
          <div class="items"> {{ item }}</div>
          <van-icon v-if="isDel" name="cross" color="#999999" @click.stop="handleDel(idx)" />
        </div>
      </div>
      <div class="box-main">
        <div class="history-item" @click="handeleSearchFor(item)" v-for="(item,idx) in currentdata" :key="idx">
          <div class="items"> {{ item }}</div>
          <van-icon v-if="isDel" name="cross" color="#999999" @click="handleDel(idx)" />
        </div>
        <div class="btn" @click="partcahnge" v-if="btnShow">
          <span v-if="part"><van-icon name="arrow-down" /></span>
          <!-- <span v-else><van-icon name="arrow-up" /></span> -->
        </div>
      </div>
    </div>
    <!-- 热门搜索 -->
    <div class="search-box" v-if="hotlist?.length">
      <div class="box-header">
        <div class="title">{{ $t('热门搜索') }}</div>
      </div>
      <div class="box-main">
        <div class="hot-item" v-for="(item,idx) in hotlist" :key="idx" @click="handleLink(item)">
          {{ item.keyWord }}
        </div>
      </div>
    </div>
    <van-dialog v-model:show="isOpen" :showConfirmButton="false">
      <div class="register-dialog">
        <div class="register-text">
          <div>{{ $t("確認刪除？") }}</div>
        </div>
        <div class="register-btn">
          <div class="Cancel" @click="isOpen = false">{{ $t("取消") }}</div>
          <div class="Confirm" @click="handleConfirm">{{ $t("確認") }}</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import search from "@/components/search/index"
import Config from "@/utils/config.js"
import { getToken, rmoveToken } from "@/utils/token"
import Mixins from "@/utils/mixins"
import MyStorage from "@/utils/storage"
import { gethotWord, getProductList } from "@/api/search"
const history_records = new MyStorage("history_records")
export default {
  mixins: [Mixins],
  components: {
    search,
  },
  data () {
    return {
      islogin: null,
      imgUrl: Config.imgUrl,
      defaultImg: require('@/assets/image/e-Rith.png'),
      isDel: false,
      isOpen: false,
      historyList: [],
      hotlist: [],
      currentdata: [],
      TwoRowData: [],
      FiveRowData: [],
      part: true,
      btnShow: true,
      wid1: 0,
      wid2: 0,
      boxWidth: 0
    }
  },
  mounted () {
    this.historyList = JSON.parse(history_records.get()) || []
    // this.islogin = !getToken()
    this.getData()

    this.$nextTick(() => {
      const boxMain = this.$refs.boxMain
      // 获取 box 宽度
      this.boxWidth = boxMain?.clientWidth
      // 获取所有子元素
      const childElements = this.$refs.childElements
      if (!childElements?.length) return
      // // 获取单个元素的向右外边距
      const style = window.getComputedStyle(childElements[0])
      const marginRight = parseFloat(style.marginRight)
      try {
        childElements.forEach((item, idx) => {
          this.wid1 += Math.round(item.clientWidth + marginRight)
          if (this.wid1 > this.boxWidth) {
            this.wid2 += this.wid1
          }
          if (this.wid2 <= ((this.boxWidth * 2) - 40)) {
            this.TwoRowData.push(this.historyList[idx])
            this.currentdata = this.TwoRowData
          }

          if (this.wid1 <= ((this.boxWidth * 5) - 40)) {
            this.FiveRowData.push(this.historyList[idx])
          }

        })

      } catch {
      } finally {
        if (this.wid2 <= ((this.boxWidth * 2) - 40)) {
          this.btnShow = false
        }
        boxMain.classList.add('hide')

      }
    })
  },
  methods: {
    partcahnge () {
      if (this.part) {
        this.part = false
        // this.currentdata = this.historyList
        this.currentdata = this.FiveRowData
      } else {
        this.part = true
        this.currentdata = this.TwoRowData
      }

    },
    handleisDel () {
      this.isDel = true
      this.part = false
      this.currentdata = this.FiveRowData
    },
    // 确认删除
    handleConfirm () {
      this.isDel = false
      this.historyList = []
      history_records.remove()
    },
    // 全部删除
    handleAlldel () {
      this.isOpen = true
    },
    // 确定清除缓存
    handleFinish () {
      this.isDel = false
      history_records.set(JSON.stringify(this.historyList))
    },
    // 单独删除
    handleDel (idx) {
      this.historyList.splice(idx, 1)
      this.currentdata.splice(idx, 1)
    },
    // 热门跳转
    async handleLink (data) {
      if (data.link) {
        const isFontIcon = data?.link.substring(0, 1) == "/" ? true : false
        this.$router.push(`${!isFontIcon ? '/' : ''}${data?.link}`)
        return
      }
      await this.handeleSearchFor(data.keyWord)
    },
    // 点击历史跳转搜索
    handeleSearchFor (data) {
      if (this.isDel) return
      const keyWord = data.trim()
      if (keyWord) {
        const list = JSON.parse(history_records.get()) || []
        list.unshift(keyWord)
        const uniqueArr = list.filter((item, index) => list.indexOf(item) === index)
        // 最大 30 条，清除多出
        if (uniqueArr.length >= 30) {
          const length = uniqueArr.length - 30
          uniqueArr.splice(30, length)
        }
        history_records.set(JSON.stringify(uniqueArr))
        this.$router.push({
          path: "/productList",
          query: { name: keyWord }
        })
      }
    },
    async getData () {
      const res = await gethotWord()
      if (res.resultID === 1200) {
        this.hotlist = res.data
      }
    }
  },
  beforeDestroy () {

  }

}
</script>

<style lang="scss" scoped>
.page {
  background-color: #ffffff;
  height: 100vh;
}

.search-box {
  width: 343px;
  margin: 20px auto 0;

  .box-header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0;

    .title {
      font-family: OPPOSans B, OPPOSans B;
      font-weight: 400;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 24px;
    }

    .edit-img {
      width: 20px;
      height: 20px
    }

    .box-header-right {
      display: flex;
      align-items: center;

      .allDel {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 22px;
      }

      .line {
        width: 1px;
        height: 14px;
        background-color: #EBEBEB;
        margin: 0 8px;
      }

      .finish {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #1A1A1A;
        line-height: 22px;
      }
    }
  }

  .box-main {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &.hide {
      display: none !important;
    }

    .history-item {
      padding: 4px 12px;
      margin-right: 8px;
      margin-bottom: 8px;
      box-sizing: border-box;
      background: #F8F8F8;
      border-radius: 16px 16px 16px 16px;
      display: flex;
      align-items: center;

      .items {
        max-width: 132px;
        overflow: hidden;
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
      }
    }

    .btn {
      width: 40px;
      text-align: center;
      line-height: 28px;
      box-sizing: border-box;
      border-radius: 20px;
      background-color: #F8F8F8;
      margin-bottom: 8px;
      cursor: pointer;
    }


    .hot-item {
      padding: 4px 12px;
      margin-right: 8px;
      margin-bottom: 8px;
      box-sizing: border-box;
      background: #F8F8F8;
      border-radius: 16px 16px 16px 16px;
      max-width: 330px;
      overflow: hidden;
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
    }
  }
}
.register-dialog {
  padding: 24px;
}

.register-text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #1a1a1a;
  text-align: center;

  span {
    color: #3491fa;
  }
}

.register-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Cancel,
  .Confirm {
    flex: 1;
    text-align: center;
    margin-top: 32px;
  }

  .Cancel {
    background: #eeeeee;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
    margin-right: 12px;
  }

  .Confirm {
    font-size: 16px;
    color: #ffffff;
    background-color: #90d12e;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
  }
}
</style>