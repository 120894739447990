import { getShoppingCartList2 } from "@/api/shopCart"
const state = {
  cartList: [],
  cartCount: null,
}
const getters = {}
const mutations = {
  setgoodsList (state, cartList) {
    state.cartList = cartList
  },
  setCount (state, cartCount) {
    state.cartCount = cartCount == 0 ? null : cartCount
  },
}
const actions = {
  setgoodsList (context, data) {
    context.commit("setgoodsList", data)
  },
  setCount (context, data) {
    context.commit("setCount", data)
  },
  //获取购物车数量
  async getCartCount (context, data) {
    const res = await getShoppingCartList2({ showSelected: false })
    if (res.resultID == 1200) {
      const list = res.data.list.map(item => item.goods).flat(1)
      if (list.length) {
        const count = list.reduce(
          (prve, next) => Number(prve) + Number(next.amount),
          0
        )
        context.commit("setCount", count)
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
