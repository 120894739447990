<!--
 * @version: v 1.0.0
 * @Github: https://github.com/GitHubGanKai
 * @Author: GitHubGanKai
 * @Date: 2021-01-01 13:57:27
 * @LastEditors: gankai
 * @LastEditTime: 2021-01-02 15:07:37
 * @FilePath: /refactor-with-vue3/src/components/tabbar/index.vue
-->
<template>
  <div class="tabbar">
    <van-tabbar route v-model="active" @change="tabChange(active)" active-color="#90D12E" inactive-color="#999999"
      style="z-index: 100">
      <van-tabbar-item name="home" icon="wap-home" to="/home">
        {{ $t('首頁') }}
      </van-tabbar-item>
      <!-- <van-tabbar-item name="classify" icon="apps-o" to="/classify">
        分类
      </van-tabbar-item> -->
      <!-- <van-tabbar-item name="shopCart" icon="shopping-cart" to="/shopCart" :info="cartCount" :badge="cartCount">
        {{ $t('購物車') }}
      </van-tabbar-item> -->
      <van-tabbar-item name="userinformation" icon="contact" to="/user/information">
        {{ $t('我的') }}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { computed, ref, getCurrentInstance } from "vue"
import { useStore, mapState } from "vuex"
export default {
  name: "tabbar",
  setup (props, context) {
    const { attrs, emit, slots } = context
    const currentInstance = getCurrentInstance()

    const { ctx } = currentInstance
    console.log('ctx', ctx)

    const active = ref(0)

    // ctx.$eventBus.$on(
    //   "changeTag",
    //   tag => {
    //     active.value = tag;
    //   },
    //   currentInstance
    // );

    return {
      active,
    }
  },
  computed: {
    ...mapState("shopCart", ["cartCount"]),

  },
  methods: {
    tabChange (active) {
      console.log("==active=====>", active)
    }
  }
}
</script>
