export default [
  {
    path: '/searchPage',
    name: 'searchPage',
    meta: {
      hideTab:true
    },
    component: () => import('@/views/searchPage/index.vue')
  },
  {
    path: '/productList',
    name: 'productList',
    meta: {
      hideTab:true
    },
    component: () => import('@/views/searchPage/productList.vue')
  },
  {
    path: '/allAssort',
    name: 'allAssort',
    meta: {
      hideTab:true
    },
    component: () => import('@/views/AllAssort/index.vue')
  }
]