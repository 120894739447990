<template>
  <div class="page">
    <nav-bar :isFixed="true" :title="$t('全部品類')" v-if="!isMobileTerminal()" :bgc="'background:#fff'"></nav-bar>
    <div class="content">
      <van-tabs type="line" v-model="active" color="#90D12E" title-active-color="#90D12E" title-inactive-color="#666666"
        sticky="true" offset-top="44px" @click="handleChange">
        <van-tab :title="$t('全部')">
          <div class="all">
            <div class="leftMenu">
              <div :class="[langEnv==='2'?'enItem':'item',{ 'active': item.isactive }]" v-for="(item,index) in allList" :key="index"
                @click="handleTreeSelect(item,index)">{{
                  item.name
                }}</div>
            </div>
            <div class="rightMenu">
              <div class="name">{{ CategoryName }}</div>
              <div class="category-box">
                <div class="category-item" v-for="item in CategoryList" :key="item.id" @click="handleCatrgory(item.id)">
                  <div class="category-img">
                    <img :src="`${imgUrl}${item.picturePath}`" alt="" @error="handleImageError" />
                  </div>
                  <div class="category-name">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('品牌')">
          <div class="brand-box">
            <van-index-bar :index-list="indexList" :sticky="false">
              <div v-for="(item,index) in BrandList" :key="index">
                <van-index-anchor :index="item.key" />
                <div class="cell" v-for="(row,index) in item.list" :key="index" @click="handleBrand(row.link)">
                  <div class="cell-name">{{ row.title }}</div>
                  <div class="cell-avatar">
                    <img :src="`${imgUrl}${row.picturePath}`" alt="" @error="handleImageError" />
                  </div>
                </div>
              </div>
            </van-index-bar>
          </div>
        </van-tab>
      </van-tabs>

    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import Mixins from "@/utils/mixins.js"
import Config from "@/utils/config.js"
import { getParentCategoryList, getCategoryListById, getSearchBrand } from "@/api/search"
import { getToken } from "@/utils/token.js"
import i18n from "@/i18n"
export default {
  name: 'allAssort',
  data () {
    return {
      imgUrl: Config.imgUrl,
      defaultImg: require('@/assets/image/e-Rith.png'),
      active: '0',
      allList: [],
      CategoryName: '',
      CategoryList: [],
      BrandList: [],
      indexList: [],
      langEnv:''
    }
  },
  mixins: [Mixins],
  created () {
    this.langEnv = window.localStorage.getItem("langEnv")
    this.getData()
  },
  methods: {
    handleBrand (data) {
      console.log('data', data)
      this.$router.push({
        path: "/productList",
        query: { brandID: data }
      })
    },
    handleCatrgory (data) {
      this.$router.push({
        path: "/productList",
        query: { CategoriesID: data }
      })
    },
    handleChange (e) {
      if (e === 0) {
        this.getData()
      } else {
        this.getBrandData()
      }

    },
    handleTreeSelect (data, index) {
      this.allList.forEach((ele, idx) => {
        if (idx === index) {
          if (data.isactive) return
          ele.isactive = true
          this.CategoryName = ele.name
          this.getDataID(ele.id)
        } else {
          ele.isactive = false
        }
      })
    },
    async getData () {
      const res = await getParentCategoryList({})
      this.allList = await res.data?.categories.map(item => {
        return {
          ...item,
          isactive: false
        }
      })
      this.allList[0].isactive = await true
      this.CategoryName = this.allList[0].name
      this.getDataID(this.allList[0].id)
    },
    async getDataID (id) {
      const res = await getCategoryListById({ id })
      this.CategoryList = res.data
    },
    async getBrandData () {
      const res = await getSearchBrand({})
      this.BrandList = res.data
      this.indexList = res.data.map(item => item.key)

    },
    handleImageError (e) {
      e.target.src = this.defaultImg // 只需要将此路径换成对应的默认图片路径即可
    },
  }
}
</script>
<style scoped lang="scss">
// .page {
//   background: #ffffff;
// }

.content {
  width: 100%;
  margin-bottom: 12px;
  padding-top: 44px;

  .all {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;

    .leftMenu {
      width: 96px;
      height: 90vh;
      overflow: hidden; //超出隐藏
      overflow-y: auto; //出现水平滚动条

      &::-webkit-scrollbar {
        display: none;
      }
      .enItem{
        padding: 0 8px;
        box-sizing: border-box;
        width: 100%;
        height: 52px;
        font-family: PingFang SC, PingFang SC;
        line-height: 1;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        border-radius: 0px 12px 0px 0px;
        overflow: hidden;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
          background: #FFFFFF;
          font-size: 12px;
          color: #90D12E;
          border-radius: 0px;
        }
      }
      .item {
        // padding: 0 8px;
        box-sizing: border-box;
        width: 100%;
        height: 52px;
        font-family: PingFang SC, PingFang SC;
        line-height: 52px;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        word-break: break-all;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        &.active {
          background: #FFFFFF;
          font-size: 12px;
          color: #90D12E;
          border-radius: 0px;
        }
      }
    }

    .rightMenu {
      width: 279px;
      height: 90vh;
      overflow: hidden; //超出隐藏
      overflow-y: auto; //出现水平滚动条
      background-color: #FFFFFF;

      &::-webkit-scrollbar {
        display: none;
      }

      .name {
        height: 52px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #1A1A1A;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .category-box {
        width: 247px;
        margin: 16px auto 0;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        overflow: hidden;

        .category-item {
          width: 72px;
          margin-right: 14px;
          margin-bottom: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .category-img {
            width: 64px;
            height: 64px;
            font-size: 0;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .category-name {
            margin-top: 4px;
            // height: 40px;
            width: 100%;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 13px;
            color: #1A1A1A;
            line-height: 20px;
            text-align: center;
            // overflow: hidden;
            word-break: break-all;
          }
        }

        & :nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }

  .brand-box {
    margin:0 auto;
    padding: 12px 44px 12px 12px;
    height: 90vh;
    box-sizing: border-box;
    overflow: hidden; //超出隐藏
    overflow-y: auto; //出现水平滚动条
    background-color: #FFFFFF;

    &::-webkit-scrollbar {
      display: none;
    }

    .cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px;

      .cell-name {
        width: 247px;
        font-family: PingFang SC, PingFang SC;
        font-size: 16px;
        color: #1A1A1A;
        line-height: 24px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .cell-avatar {
        width: 48px;
        height: 48px;
        font-size: 0;

        .img {
          width: 100%;
          height: 100%;
        }
      }
    }

    :deep(.van-index-anchor) {
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 16px;
      color: #1A1A1A;
      line-height: 24px;
      height: 36px;
    }

    :deep(.van-index-bar__sidebar) {
      width: 20px;
      padding: 12px 0;
      background: #F8F8F8;
      border-radius: 12px 12px 12px 12px;
      right: 12px;
      transform: translateY(-45%);
    }

    :deep(.van-index-bar__index) {
      padding: 0;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: #666666;
      line-height: 20px;
    }

    :deep(.van-index-bar__index--active) {
      color: #90D12E
    }
  }
}
</style>
