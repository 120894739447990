<template>
  <van-popup v-model:show="isShow" position="bottom" :style="{
    height: '80vh',
    borderRadius: '16px 16px 0 0',
    padding: '0 16px',
    overflow: 'hidden'
  }" @closed="handleClose">
    <div class="close">
      <img @click="handleClose" src="@/assets/icons/ic_Close@2x.png" alt="" />
    </div>
    <div class="content-box">
      <div class="content">
        <div class="title">{{ $t("可用優惠券") }}</div>
        <couponList :list="canGetDisCoupons" @change="handeChange" />
        <div class="title" style="padding-top:16px ;">{{ $t("不可用優惠券") }}</div>
        <couponList :list="list2" />
      </div>
    </div>
    <div class="btn" @click="handleSuccess()">
      {{$t("确定")}}
    </div>
  </van-popup>
</template>
<script>
import Mixins from "@/utils/mixins"
import { getProductSpecification } from "@/api/productdetails.js"
import couponList from "@/components/SelectCountDialog/components/index.vue"
export default {
  name: "SelectCountDialog",
  props: {
    list: {
      type: Array,
      default: []
    },
    list2: {
      type: Array,
      default: []
    },
  },
  watch: {
    list: {
      handler (val) {
        this.canGetDisCoupons = val
      },
      deep: true
    }
  },
  components: {
    couponList
  },
  data () {
    return {
      isShow: false,
      canGetDisCoupons: []
    }
  },
  mixins: [Mixins],
  async created () { },

  async mounted () {
  },
  methods: {
    handeChange (row) {
      this.canGetDisCoupons.forEach(item => {
        if (item.couponId === row.couponId) {
          if (item.active) {
            item.active = false
          } else {
            item.active = true
          }

        } else {
          item.active = false
        }
      })
    },
    handleSuccess () {
      const data = this.canGetDisCoupons.filter(item => item.active)[0] || {}
      this.$emit('success', data)
      this.handleClose()
    },
    handleClick (data) {
      this.handleClose()
      this.$router.push(`${data.link}`)
    },
    show () {
      this.isShow = true
    },
    handleClose () {
      this.isShow = false
    },
  }
}
</script>
<style scoped lang="scss">
.close {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 0;

  img {
    width: 24px;
    height: 24px;
  }
}

.btn {
  position: absolute;
  bottom: 6px;
  left: 16px;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #90D12E;
  font-weight: 500;
  font-size: 15px;
  color: #90D12E;
  line-height: 44px;
  text-align: center;
  height: 44px;
  width: 92%;
}

.content-box {
  height: 100%;
  padding: 40px 0px 60px;
  box-sizing: border-box;
}

.content {

  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 24px;
    text-align: left;
    margin-bottom: 4px;
  }

  .tigs {
    margin-top: 4px;
    font-weight: 400;
    font-size: 13px;
    color: #666666;
    line-height: 20px;
    text-align: left;
    margin-bottom: 16px;
  }

  .box {
    display: flex;
    align-items: flex-start;

    .left {
      .tag {
        width: 30px;
        height: 18px;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #F6B6AE;
        font-weight: 400;
        font-size: 11px;
        color: #E84935;
        line-height: 18px;
        text-align: center;
      }
    }

    .right {
      margin-left: 6px;

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .text {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 13px;
          color: #1A1A1A;
          line-height: 20px;
        }

        .icon-arrow {
          margin-left: 6px;
        }
      }
    }
  }
}
</style>
