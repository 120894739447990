import baseURL from "@/utils/config"
import request from "@/plugins/axios"
const langEnv = window.localStorage.getItem("langEnv")
// 获取购物车列表
export function getShoppingCartList2 (data) {
  return request({
    url: baseURL.api + "/authapi/shoppingcart/getShoppingCartList2",
    method: "post",
    data,
  })
}

// 修改购物车
export function changeShoppingCart (data) {
  return request({
    url: baseURL.api + "/authapi/shoppingcart/changeShoppingCart",
    method: "post",
    data,
  })
}

// 删除购物车商品
export function deleteShoppingCart2 (data) {
  return request({
    url: baseURL.api + "/authapi/shoppingcart/deleteShoppingCart2",
    method: "post",
    data,
  })
}
// 添加购物车
export function addShoppingCart (data) {
  return request({
    url: baseURL.api + '/authapi/shoppingcart/addShoppingCart',
    method: "post",
    data
  })
}
// 添加购物车
// export function getAllStandard (data) {
//   return request({
//     url: baseURL.api + '/authapi/shoppingcart/getAllStandard',
//     method: "post",
//     data
//   })
// }
